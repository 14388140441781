import React, { useEffect, useState } from 'react';
import { useToggle } from '@oneloop/hooks';
import { Box, Text, Icon, Heading } from '@oneloop/jopijs';
import styles from '../CustomHeader/CustomHeader.module.scss';
import LinkWithQR from './LinkWithQR';
import MessengerService from '@/services/MessengerService';

function LinkWithPanel({ setVincular }) {
  const [isqrCode, setisQrCode] = useToggle(false);
  const [qrCode, setQrCode] = useState('');

  useEffect(() => {
    if (isqrCode) return;
    const getQrCode = async () => {
      const qrcode = await MessengerService.getWhatsappQrCode();
      console.log(qrcode);
      setisQrCode(true);
      setQrCode(qrcode.qrcode);
    };
    getQrCode();
  }, [qrCode]);

  return (
    <Box className={styles.linkWithWhatsappBox}>
      <Box style={{ display: 'flex' }}>
        <Icon icon="icon-whatsapp" fontSize="36px" color="#25d366" />
        <Text
          style={{
            fontWeight: '800',
            fontSize: '25px',
            color: '#25d366',
            alignItems: 'center',
          }}
        >
          WhatsApp
        </Text>
      </Box>
      <Heading as="h4" variant="heading.3">
        Scan with your device
      </Heading>
      <Box
        style={{
          width: '240px',
          height: '240px',
          borderRadius: '32px',
          border: '2px solid #00D186',
          background: '#FFF',
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {isqrCode && (
          <Box style={{ cursor: 'pointer' }} onClick={() => setVincular(false)}>
            <LinkWithQR text={qrCode} />
          </Box>
        )}
      </Box>
      <Text
        variant="body.fontSize16"
        style={{ width: '420px', textAlign: 'center', color: '#344149' }}
      >
        All users with inquiries permissons will see new WhatsApp messages and
        assign them to other agents
      </Text>
    </Box>
  );
}

export default LinkWithPanel;
