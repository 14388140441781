/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import {
  useChannelStateContext,
  useChatContext,
  useTypingContext,
} from 'stream-chat-react';
import styles from './CustomTypingIndicator.module.scss';

function CustomTypingIndicator() {
  const { channelConfig } = useChannelStateContext();
  const { client } = useChatContext();
  const { typing = {} } = useTypingContext();

  if (channelConfig?.typing_events === false) {
    return null;
  }

  const typingInChannel = Object.values(typing).filter(
    ({ parent_id, user }) => user?.id !== client.user?.id && !parent_id
  );

  const [dots, setDots] = useState('');

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prevDots) => (prevDots.length < 3 ? `${prevDots}.` : ''));
    }, 500);

    return () => clearInterval(interval);
  }, []);

  const isTyping = typingInChannel?.length > 0;

  return (
    <div
      className={`${styles.typingBox} ${
        isTyping ? styles.typingBoxActive : ''
      }`}
    >
      <div className="str-chat__typing-indicator__avatars">
        {typingInChannel?.map(({ user }, i) => (
          <div className={styles.username} key={i}>
            <div className={styles.typingName}>
              {user?.name} está escribiendo{dots}
            </div>
          </div>
        ))}
      </div>
      <div className={styles.typingDots}>
        <div className={styles.typingDot} />
        <div className={styles.typingDot} />
        <div className={styles.typingDot} />
      </div>
    </div>
  );
}

export default CustomTypingIndicator;
