import { Box, Icon, Loader, Tags, Text } from '@oneloop/jopijs';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import List from './List';
import style from '../QuickDisplay.module.scss';
import {
  getHighLightContacts,
  starDevelopment,
  starProperty,
} from '@/store/slices/components/quickDisplay/services';
import translations from '../QuickDisplay.trans.json';
import Trans from '@/components/shared/Trans';
import {
  addHighlightContact,
  resetData,
  resetStarredContact,
} from '@/store/slices/components/quickDisplay/QuickDisplaySlice';
import '../QuickDisplay.css';
import {
  keyNavegation,
  lightenColor,
  resetIndexList,
} from '@/components/shared/Hooks';
import { showToast } from '@/store/slices/components/toast/ToastSlice';
import ToolTip from '@/components/ToolTip/ToolTip';
import { tokkoModalActions } from '../../TokkoActions/tokkoActions';
import Checkbox from '@/components/checkbox/Checkbox';

export default function HighlightProperty() {
  const tokkoBrokerHost = localStorage.getItem('tokkoBrokerHost');
  const [showDD, setShowDD] = useState(false);
  const [query, setQuery] = useState('');
  const dispatch = useDispatch();
  const { id, highlight, isDevelopment, isContact, starredSingledContact } =
    useSelector((state) => state.QuickDisplaySlice);

  const { loading, contacts, highlightContacts, starLoading } = highlight;
  const ulRef = useRef(null);
  const inputRef = useRef(null);

  let debounceTimer;
  const handleAutoComplete = useCallback((q) => {
    resetIndexList();
    setQuery(q);
    const clearQuery = q.trim();

    if (clearQuery.length >= 3) {
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(async () => {
        dispatch(getHighLightContacts(clearQuery));
      }, 1500);
    }
  }, []);

  const clearInput = () => {
    setQuery('');
    dispatch(resetData('highlight'));
    resetIndexList();
  };

  const clearData = () => {
    setQuery('');
    resetIndexList();
    dispatch(resetStarredContact());
  };

  const closeModal = () => {
    setShowDD(false);
    clearInput();
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' || e.key === 'ArrowDown' || e.key === 'ArrowUp') {
      keyNavegation(e.key, ulRef);
    }
    if (e.key === 'Backspace') {
      resetIndexList();
    }
  };

  useEffect(() => {
    if (showDD) {
      inputRef.current.focus();
    }
  }, [showDD]);

  const fetchData = async (getDataFunction) => {
    if (!isContact && Boolean(!highlightContacts[0])) return;

    const ids = highlightContacts.map((item) => item.id);
    const keyText = isDevelopment
      ? 'YouStarredThisDevelopmentFor'
      : 'YouStarredThisPropertyFor';

    const toastText =
      ids.length === 1 || isContact
        ? `${Trans(translations, keyText)} ${
            isContact.name || highlightContacts[0].name
          }`
        : `${Trans(translations, keyText)} ${ids.length} ${Trans(
            translations,
            'contacts'
          )}`;

    try {
      await dispatch(
        getDataFunction({
          idProp: id,
          idsContacts: isContact ? [isContact.id] : ids,
        })
      );

      closeModal();

      if (isContact) {
        tokkoModalActions(
          { msg: isDevelopment ? 'fetchStarredDev' : 'fetchStarredProp' },
          tokkoBrokerHost
        );
      }

      dispatch(showToast({ text: toastText }));
    } catch (error) {
      dispatch(showToast({ text: 'error', type: 'danger' }));
    }
  };

  const handleStarProperty = () => {
    if (isDevelopment) {
      return fetchData(starDevelopment);
    }

    return fetchData(starProperty);
  };

  const handleAction = () => {
    if (isContact) {
      return handleStarProperty(isContact.id);
    }

    return setShowDD(!showDD);
  };

  const toolTipText = isContact
    ? `${Trans(translations, 'StarFor')} ${isContact.name}`
    : Trans(translations, 'StarForAContact');

  const handleIsStarred = (contact) => {
    try {
      const { starred } = contact;

      const key = isDevelopment ? 'developments' : 'properties';

      return starred[key].includes(Number(id));
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  return (
    <Box>
      <ToolTip
        title={
          starredSingledContact
            ? `${Trans(translations, 'StarredTo')} ${isContact.name}`
            : toolTipText
        }
        outSideModal="QuickDisplay"
      >
        <Box
          data-testid="icon-destaque"
          className={`${style.itemHeader} ${
            starredSingledContact ? style.iconDisabled : ''
          }`}
          onClick={handleAction}
        >
          <Icon icon="icon-destaque" fontSize="16px" />
        </Box>
      </ToolTip>

      <List
        className={style.starredList}
        open={showDD}
        seter={closeModal}
        clickable
        width="350px"
      >
        <Box className={style.highlightCont}>
          <Box className={style.highlightHeader}>
            <Text variant="bodyBold.fontSize14">
              {Trans(
                translations,
                isDevelopment ? 'StarDevelopment' : 'StarProperty'
              )}
            </Text>
            <Icon icon="icon-cerrar" fontSize="16px" onClick={closeModal} />
          </Box>

          <Box className={style.highlightSearchBox}>
            <Icon icon="icon-buscar" fontSize="16px" />
            <Box
              as="input"
              ref={inputRef}
              value={query}
              placeholder={Trans(translations, 'FindContactToStar')}
              onChange={(e) => handleAutoComplete(e.target.value)}
              onKeyDown={handleKeyDown}
            />

            {query &&
              (loading ? (
                <Loader
                  variant="spinner"
                  style={{
                    width: '16px',
                    height: '16px',
                    backgroundImage: ' conic-gradient(#878C9B ,#FFFFFF)',
                  }}
                />
              ) : (
                <Text variant="bodyBold.fontSize12" onClick={clearData}>
                  {Trans(translations, 'Clear')}
                </Text>
              ))}
          </Box>

          <Box as="ul" ref={ulRef} className={style.listHighlight}>
            {contacts.map((item) => {
              if (item.id) {
                return (
                  <Box
                    as="li"
                    key={`contact-${item.id}`}
                    __css={{
                      padding: '8px 28px !important',
                      justifyContent: 'space-between',
                    }}
                    onClick={() => dispatch(addHighlightContact(item))}
                    className={`${
                      handleIsStarred(item) ? style.isStarred : ''
                    }`}
                  >
                    <Box
                      __css={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <Box className={style.highlightNameBox}>
                        {!handleIsStarred(item) && (
                          <Checkbox
                            checked={highlightContacts.some(
                              (i) => i.id === item.id
                            )}
                          />
                        )}
                        <Text variant={['body.fontSize14', 'ellipsis']}>
                          {item.name}
                        </Text>
                        <Box
                          as="span"
                          style={{
                            borderColor:
                              item.status.color === 'FFFFFF'
                                ? '#dde5e9'
                                : `#${item.status.color}`,
                            background: lightenColor(
                              `#${item.status.color}`,
                              30
                            ),
                          }}
                        >
                          <Text variant={['body.fontSize12', 'ellipsis']}>
                            {item.status.name}
                          </Text>
                        </Box>
                      </Box>
                      {item.contact_info?.email && (
                        <Box
                          className={style.iconText}
                          __css={{ marginBottom: '6px' }}
                        >
                          <Icon icon="icon-email" fontSize="16px" />
                          <Text variant="body.fontSize12">
                            {item.contact_info.email}
                          </Text>
                        </Box>
                      )}
                      {(item.contact_info?.phone ||
                        item.contact_info?.cellphone) && (
                        <Box className={style.iconTextCont}>
                          {item.contact_info?.phone && (
                            <Box className={style.iconText}>
                              <Icon icon="icon-telefono" fontSize="16px" />
                              <Text variant="body.fontSize12">
                                {item.contact_info.phone}
                              </Text>
                            </Box>
                          )}
                          {item.contact_info?.cellphone && (
                            <Box className={style.iconText}>
                              <Icon icon="icon-whatsapp" fontSize="16px" />
                              <Text variant="body.fontSize12">
                                {item.contact_info.cellphone}
                              </Text>
                            </Box>
                          )}
                        </Box>
                      )}
                    </Box>

                    {handleIsStarred(item) && (
                      <Box className={style.iconStarredProp}>
                        <Icon icon="icon-destaque" fontSize="24px" />
                      </Box>
                    )}
                  </Box>
                );
              }

              return (
                <Text variant="body.fontSize14" className={style.noDataText}>
                  {Trans(translations, item.noData)}
                </Text>
              );
            })}
          </Box>

          <Box className={style.tagsContainer}>
            {highlightContacts.map((item) => (
              <Tags
                key={`starred-${item.id}`}
                variant="default"
                closeIcon={() => dispatch(addHighlightContact(item))}
              >
                {item.name}
              </Tags>
            ))}
          </Box>

          {highlightContacts.length > 0 && (
            <Box
              className={`${style.exportButton} ${
                starLoading ? style.disabled : ''
              }`}
              __css={{ mx: '16px' }}
              tabIndex={0}
              onClick={handleStarProperty}
            >
              <Text variant="bodyBold.fontSize14">
                {Trans(
                  translations,
                  isDevelopment ? 'StarDevelopment' : 'StarProperty'
                )}
              </Text>
              {starLoading && (
                <Box className={style.spinnerCont}>
                  <Loader
                    variant="spinner"
                    style={{
                      backgroundImage: 'conic-gradient(#878C9B ,#FFFFFF)',
                      margin: '0px',
                      width: '20px',
                      height: '20px',
                    }}
                  />
                </Box>
              )}
            </Box>
          )}
        </Box>
      </List>
    </Box>
  );
}
