import React, { useEffect, useState, useRef } from 'react';
import {
  Box,
  ButtonIcon,
  Drawer,
  Heading,
  Text,
  Tags,
  Icon,
  ParentTooltip,
  Tooltip,
} from '@oneloop/jopijs';
import {
  useChannelStateContext,
  useChatContext,
  Avatar,
} from 'stream-chat-react';
import { isMouseOutParent, useToggle } from '@oneloop/hooks';
import { useSelector } from 'react-redux';
import GroupDrawer from './Profiles/GroupProfile';
import UserProfile from './Profiles/UserProfile';
import AvatarGroup from '../AvatarGroup/AvatarGroup';
import AsignInquiry from './Asign/AsignInquiry';
import styles from './CustomChannelHeader.module.scss';
import translations from './CustomChannelHeader.trans.json';
import Trans from '@/components/shared/Trans';
import MessengerService from '@/services/MessengerService';
import InquiriesComponent from './Inquiry/InquiryComponent';
import FilesInProfile from './FilesInProfile/FilesInProfile';
import useOnClickOutside from '@/components/shared/Hooks';
import ProfileIconType from './ProfileIconType/ProfileIconType';

function CustomChannelHeader() {
  const { client, setActiveChannel } = useChatContext();
  // eslint-disable-next-line no-unused-vars
  const { channel, watcherCount } = useChannelStateContext();
  const channelName = channel.data?.name || '';
  const [title, setTitle] = useState('');
  const members = Object.values(channel.state.members || {}).filter(
    (member) => member.user?.id !== client?.user?.id
  );
  const [inquiries, setInquiries] = useState([]);
  const [pageInfo, setPageInfo] = useState([]);
  const [isOpenFiles, setIsOpenFiles] = useToggle(false);
  const [duplicateContacts, setDuplicateContacts] = useState([]);
  const [hover, setHover] = useToggle(false);

  const { user_perms } = useSelector((state) => state.AuthSlice.permissions);

  useEffect(() => {
    if (channel?.data?.member_count < 3 || channel?.data?.web_contact) {
      const fetchContacts = async () => {
        try {
          const response = await MessengerService.duplicateContacts(
            channel?.data?.web_contact
          );
          if (response && response.contacts) {
            // Convertimos el client.user.id a string una sola vez, fuera del filter
            const clientUserId = String(client?.user?.id);
            // Filtramos usando una conversión simple de agent.id a string para la comparación
            const filteredContacts = response.contacts.filter(
              (contact) => String(contact.agent?.id) !== clientUserId
            );
            setDuplicateContacts(filteredContacts);
          } else {
            console.error('No data available.');
          }
        } catch (error) {
          setDuplicateContacts('');
        }
      };

      fetchContacts();
    }
  }, [client.user.id, channel?.data?.member_count]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const inquiryData = await MessengerService.getInquiry(
          channel.data.id,
          1
        );
        setInquiries(inquiryData.contacts);
        setPageInfo(inquiryData.page_info);
      } catch (error) {
        console.error('Error al obtener datos del grupo:', error);
      }
    };
    fetchData();
  }, []);
  useEffect(() => {
    if (!channelName) {
      setTitle(
        members
          .map(
            (member) => member.user?.name || member.user?.id || 'Unnamed User'
          )
          .join(', ')
      );
    }
  }, [channelName, members]);
  // cambiar el toggle por setIsOpen
  const [isOpen, toggle] = useToggle(false);
  const [isOpenAsign, toggleAsign] = useToggle(false);
  const [moreOpportunities, toggleOpportunities] = useToggle(false);
  const overlay = false;
  const numberOfUsers = Object.keys(channel.state.members).length;

  function formatDate(dateString) {
    const date = new Date(dateString);
    const today = new Date();

    const options = { weekday: 'short', day: 'numeric', month: 'long' };

    if (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    ) {
      return 'Hoy';
    }

    return new Intl.DateTimeFormat('es-ES', options).format(date);
  }

  const [openedOptionId, setOpenedOptionId] = useState(0);

  const totalInquiries = inquiries.length;
  const getCleanImage = (member) => {
    const cleanImage = member?.user?.image || '';
    return cleanImage;
  };
  const [inquiryNumber, setInquiryNumber] = useState(2);
  //  Se definen los usuarios para el preview de cantidad de usuarios en el grupo
  const handleGetInquiryWithIncrement = async () => {
    try {
      if (pageInfo.num_pages >= inquiryNumber) {
        const inquiryData = await MessengerService.getInquiry(
          channel.data.id,
          inquiryNumber
        );
        setInquiries((prevInquiries) => [
          ...prevInquiries,
          ...inquiryData.contacts,
        ]);
        setInquiryNumber((prevNumber) => prevNumber + 1);
      }
    } catch (error) {
      console.error('Error al obtener datos del grupo:', error);
    }
  };

  function handleScroll(event) {
    const container = event.target;
    if (
      container.scrollHeight - container.scrollTop ===
      container.clientHeight
    ) {
      handleGetInquiryWithIncrement();
    }
  }
  const refHeader = useRef();
  useOnClickOutside(refHeader, isOpen, toggle);

  const [dataBranch, setDataBranch] = useState();
  const [dataUser, setDataUser] = useState(null);
  useEffect(() => {
    // Guarda data del usuario, verifica en el channel si es distinto a mi ID y trae la data del usuario
    // Este caso me sirve para un chat entre dos personas, pero no estoy contemplando si el tipo es team o colleague
    if (Object.keys(channel?.state?.members).length === 2) {
      const memberData =
        client.userID !== Object.values(channel?.state?.members)[0].user_id
          ? Object.values(channel?.state?.members)[0]
          : Object.values(channel?.state?.members)[1];
      setDataUser(memberData);
      setDataBranch(
        dataUser?.user?.userProfileExtended?.company
          ? dataUser?.user?.userProfileExtended?.company?.name
          : dataUser?.user?.company?.name
      );
    }
  }, [channel, dataUser, dataBranch]);
  return (
    <Box ref={refHeader} className={styles.container}>
      {/* {channel.data?.web_contact !== undefined &&
        !(channel.data?.web_contact?.generated_by === client?.userID)} */}
      <Box
        style={{
          display: 'flex',
          width: '100%',
          cursor: 'pointer',
          alignItems: 'center',
          height: '50px',
        }}
      >
        <Box
          style={{
            position: 'relative',
            display: 'flex',
            width: '83px',
            height: '68px',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          onClick={toggle}
        >
          <Box
            className={
              channel?.data?.frozen
                ? styles.avatarGrayScale
                : styles.avatarStyles
            }
          >
            {!channel.data.type.startsWith('WhatsApp') ? (
              <AvatarGroup members={members} name={title} />
            ) : (
              <Avatar
                className={styles.avatarStyles}
                image={channel.data.guest_image}
                shape="rounded"
                size={46}
              />
            )}
          </Box>
        </Box>
        <ProfileIconType type={channel.data.type} />
        <Box className={styles.userInfoAsign}>
          {channel.data.type.startsWith('WhatsApp') && (
            <Box>
              <Tags variant="success" noClick>
                {Trans(translations, 'NewInquiry')}
              </Tags>
              <Box style={{ display: 'flex', alignItems: 'center' }}>
                <h4>~{channel.data?.channel_header?.description}</h4>
                <Text variant="body.fontSize12">
                  {channel.data?.channel_header?.phone_number}
                </Text>
              </Box>
            </Box>
          )}
          {!channel.data.type.startsWith('WhatsApp') && (
            <Box
              style={{
                width:
                  channel?.data?.web_contact &&
                  channel?.data?.web_contact?.generated_by !== client?.userID
                    ? '505px'
                    : '100%', // validacion para que cuando no este el boton de asignar este llegue hasta el fin del contenedor
                height: '72px',
                alignItems: 'center',
                display: 'flex',
              }}
              onClick={toggle}
            >
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  flexDirection: 'column',
                }}
              >
                {dataBranch !== client.user.company.name &&
                  dataBranch !== undefined && (
                    <Tags variant="dark" noClick>
                      {dataBranch}
                    </Tags>
                  )}
                <Box
                  style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
                >
                  <h4>{channelName || title}</h4>
                  {/* {!(channel.data.member_count >= 3) && (
                    <Box
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px',
                      }}
                    >
                      <Box
                        className={
                          members[0]?.user.is_online === true
                            ? styles.profileCircle
                            : styles.profileCircleDisabled
                        }
                      />
                      {members[0]?.user.is_online && <p>En línea</p>}
                      {!members[0]?.user.is_online && <p>Ausente</p>}
                    </Box>
                  )} */}
                </Box>
              </Box>
            </Box>
          )}
          {channel?.data.web_contact &&
            channel?.data?.web_contact?.generated_by !== client?.userID &&
            user_perms?.contacts?.includes('can_change_contact_agent') && (
              <ParentTooltip
                onMouseOver={() => setHover(true)}
                onMouseOut={(e) =>
                  setHover(isMouseOutParent(e, 'parentAsingButton', 'left'))
                }
                id="parentAsingButton"
                aria-describedby="tooltipAsingButton"
                offset={2}
                placement="left"
              >
                <Box
                  onClick={
                    channel?.data?.web_contact?.assigned_agent ===
                    client?.userID
                      ? toggleAsign
                      : null
                  }
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '90px',
                    height: '72px',
                    justifyContent: 'center',
                    color:
                      channel?.data?.web_contact?.assigned_agent ===
                      client?.userID
                        ? '#344149'
                        : '#94A2AB',
                    cursor: channel?.data?.frozen ? 'not-allowed' : 'pointer',
                  }}
                >
                  <Icon
                    icon="icon-reasignar"
                    fontSize="16px"
                    color={
                      channel.data?.web_contact?.assigned_agent ===
                      client.userID
                        ? '#344149'
                        : '#94A2AB'
                    }
                  />
                  <h4
                    style={{
                      marginLeft: '8px',
                      color:
                        channel.data?.web_contact?.assigned_agent ===
                        client.userID
                          ? '#344149'
                          : '#94A2AB',
                    }}
                  >
                    {Trans(translations, 'AsignButton')}
                  </h4>
                </Box>
              </ParentTooltip>
            )}
          <div
            id="tooltipAsingButton"
            data-testid="tooltipAsingButton"
            hidden={!hover}
            className={styles.tooltip}
          >
            {channel?.data?.frozen && (
              <Tooltip
                variant="tooltipBlack"
                txVariant="arrowTooltipBlack"
                parentId="parentAsingButton"
                childrenId="tooltipAsingButton"
                offset={2}
                placement="left"
                arrow
              >
                <Text variant="body.fontSize10" style={{ color: '#FFFFFF' }}>
                  Este contacto ya fue reasignado
                </Text>
              </Tooltip>
            )}
          </div>
        </Box>
      </Box>
      {isOpen && numberOfUsers <= 2 && (
        <UserProfile
          isOpen={isOpen}
          toggle={toggle}
          members={members}
          channelName={channelName}
          title={title}
          inquiries={inquiries}
          totalInquiries={pageInfo.total}
          setOpenedOptionId={setOpenedOptionId}
          getCleanImage={getCleanImage}
          formatDate={formatDate}
          client={client}
          channel={channel}
          toggleOpportunities={toggleOpportunities}
          userID={client.userID}
          setIsOpenFiles={setIsOpenFiles}
        />
      )}
      {isOpenFiles && <FilesInProfile setIsOpenFiles={setIsOpenFiles} />}
      {isOpenAsign && numberOfUsers <= 2 && (
        <AsignInquiry
          isOpenAsign={isOpenAsign}
          toggleAsign={toggleAsign}
          webContactID={channel.data?.web_contact}
          duplicateContacts={duplicateContacts}
          webContactGeneredBy={channel.data?.web_contact?.generated_by}
          channelData={channel}
          clientData={client}
        />
      )}
      {moreOpportunities && (
        <Box className={styles.contDrawer}>
          <Drawer
            isOpen={isOpen}
            screenSide="right"
            animationMinWidth={315}
            animationWidth={315}
            overlay={overlay}
            heightMin="580px"
            animationDuration={10}
            borderRadiusClosed="20px"
            marginTop="0px"
          >
            <Drawer.Header className={styles.companyProfileBox}>
              <Box style={{ display: 'flex', alignItems: 'center' }}>
                <ButtonIcon
                  icon="icon-atras"
                  variant={['subtleTrasnparentIconClearButton', 'iconLarge']}
                  onClick={toggleOpportunities}
                />
                <Heading as="h5" variant="heading.4">
                  {Trans(translations, 'Inquiries')}
                </Heading>
              </Box>
            </Drawer.Header>
            <Drawer.Body>
              <div
                id="inquiriesBox"
                onScroll={(e) => handleScroll(e)}
                style={{
                  width: moreOpportunities ? '324px' : '330px',
                  height: '505px',
                  overflowY: 'auto',
                  marginLeft: '-9px',
                }}
              >
                {inquiries.length > 0 ? (
                  inquiries.map((inquiry) => {
                    const isOptionOpened =
                      openedOptionId === inquiry.message_id;
                    return (
                      <InquiriesComponent
                        inquiries={inquiries}
                        totalInquiries={totalInquiries}
                        formatDate={formatDate}
                        isOptionOpened={isOptionOpened}
                        inquiry={inquiry}
                        toggleIsOpen={toggle}
                        toggleOpportunities={toggleOpportunities}
                      />
                    );
                  })
                ) : (
                  <Heading
                    as="h4"
                    variant="heading.3"
                    style={{ textAlign: 'center', color: '#6b7c85' }}
                  >
                    {Trans(translations, 'NoOportunities')}
                  </Heading>
                )}
              </div>
            </Drawer.Body>
          </Drawer>
        </Box>
      )}
      {/* grupos */}
      {isOpen && numberOfUsers > 2 && (
        <GroupDrawer
          channel={channel}
          isOpen={isOpen}
          toggle={toggle}
          members={members}
          channelName={channelName}
          title={title}
          inquiries={inquiries}
          totalInquiries={totalInquiries}
          setOpenedOptionId={setOpenedOptionId}
          numberOfUsers={numberOfUsers}
          getCleanImage={getCleanImage}
          formatDate={formatDate}
          setActiveChannel={setActiveChannel}
          client={client}
        />
      )}
    </Box>
  );
}

export default CustomChannelHeader;
