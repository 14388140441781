import { createAsyncThunk } from '@reduxjs/toolkit';
import CalendarService from '@/services/CalendarService';

const handler = (name, asyncFunction) =>
  createAsyncThunk(`Calendar-events/${name}`, async (arg) => {
    const data = await asyncFunction(arg);
    return data;
  });

export const getTypes = handler('getTypes', CalendarService.getEventsTypes);

export const getAgents = handler('getAgents', CalendarService.getAgents);

export const getProperties = handler(
  'getProperties',
  CalendarService.getProperties
);

export const getContacts = handler('getContacts', CalendarService.getContacts);

export const getProperty = handler('getProperty', CalendarService.getProperty);

export const getContact = handler('getContact', CalendarService.getContact);

export const getEvents = handler('getEvents', CalendarService.getEvents);

export const getEvent = handler('getEvent', CalendarService.getEvent);

export const createEvent = handler('createEvent', CalendarService.createEvent);

export const deleteEvent = handler('deleteEvent', CalendarService.deleteEvent);

export const eventDone = handler('eventDone', CalendarService.eventDone);

export const editEvent = handler('editEvent', CalendarService.editEvent);

export const excludeDates = handler(
  'excludeDates',
  CalendarService.excludeDates
);

export const editOneInstance = handler(
  'editOneInstance',
  CalendarService.editOneInstance
);

export const doneOneInstance = handler(
  'doneOneInstance',
  CalendarService.doneOneInstance
);
