import React, { useState } from 'react';
import { Box, ButtonIcon } from '@oneloop/jopijs';
import { useMessageContext, useChannelStateContext } from 'stream-chat-react';
import styles from './CustomMessageActions.module.scss';

function CustomMessageActions({ setMessageReply, client }) {
  const { message } = useMessageContext();
  const { channel } = useChannelStateContext();
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(message.text || '');
    setShowMenu(false);
  };

  const replyToMessage = () => {
    setMessageReply(message);
    setShowMenu(false);
  };
  const getLastMessage = () => {
    const { messages } = channel.state;
    if (messages.length > 0) {
      return messages[messages.length - 1];
    }
    return null;
  };
  const lastMessage = getLastMessage();

  return (
    <Box style={{ position: 'relative', height: '0' }}>
      <Box className={styles.prueba}>
        <ButtonIcon
          icon="icon-trespunticos"
          variant={['dropdownTransparent', 'iconLarge']}
          onClick={toggleMenu}
        />
      </Box>
      {showMenu && (
        <Box
          className={
            message.user.id !== client.userID
              ? styles.messageActionsBox
              : styles.messageActionsBoxClient
          }
          style={{
            transform:
              // eslint-disable-next-line no-nested-ternary
              message.user.id !== client.userID && lastMessage.id === message.id
                ? 'translate3d(30px, -90px, 0px)'
                : lastMessage.id === message.id
                ? 'translate3d(-90px, -90px, 0px)'
                : null,
          }}
        >
          {/* {lastMessage.id === message.id ? <p>siy el ultimo</p> : <p>no soy</p>} */}
          <button
            className={styles.messageActionButton}
            type="button"
            onClick={copyToClipboard}
          >
            Copiar
          </button>
          <button
            className={styles.messageActionButton}
            type="button"
            onClick={replyToMessage}
          >
            Responder
          </button>
        </Box>
      )}
    </Box>
  );
}

export default CustomMessageActions;
