import { Button, Modal } from '@oneloop/jopijs';

export default function ConfirmModal({
  title,
  clickPrimary,
  clickSecondary,
  btnTextSecond,
  btnTextPri,
  load,
}) {
  return (
    <Modal modalBG blockScroll variant="center" scrollHeight="fit-content">
      <Modal.Header variant={['center', 'sm']} text={title} />
      <Modal.Footer variant="center">
        <Button
          variant={`${load ? 'textDisabled' : 'primaryText'}`}
          onClick={clickSecondary}
        >
          {btnTextSecond}
        </Button>
        <Button
          variant={[`${load ? 'primaryDisabled' : 'primary'}`, 'medium']}
          onClick={clickPrimary}
        >
          {btnTextPri}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
