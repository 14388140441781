/* eslint-disable import/prefer-default-export */
import ApiService from '@/services/ApiService';

class CalendarService {
  static async getEventsCalendar() {
    const composedURL = `/api3/schedule/events/rigthbar`;
    const response = await ApiService.get(composedURL);
    return response;
  }

  static async getEventsTypes() {
    const composedURL = `/api3/schedule/event_type`;
    const response = await ApiService.get(composedURL);
    return response;
  }

  static async getAgents(q) {
    const composedURL = `/api3/agent?q=${q}`;
    const response = await ApiService.get(composedURL);
    return response;
  }

  static async getProperties(q) {
    const composedURL = `/api3/property?q=${q}`;
    const response = await ApiService.get(composedURL);
    return response;
  }

  static async getContacts(q) {
    const composedURL = `/api3/contact?q=${q}`;
    const response = await ApiService.get(composedURL);
    return response;
  }

  static async getProperty(id) {
    const composedURL = `/api3/property?id=${id}`;
    const response = await ApiService.get(composedURL);
    return response;
  }

  static async getContact(id) {
    const composedURL = `/api3/contact?id=${id}`;
    const response = await ApiService.get(composedURL);
    return response;
  }

  static async getEvents(q) {
    const composedURL = `/api3/schedule/event?users=${JSON.stringify(q)}`;
    const response = await ApiService.get(composedURL);
    return response;
  }

  static async getEvent(q) {
    const composedURL = `/api3/schedule/event/${q}`;
    const response = await ApiService.get(composedURL);
    return response;
  }

  static async createEvent(data) {
    const composedURL = `/api3/schedule/event/create`;
    const response = await ApiService.post(composedURL, data);
    return response;
  }

  static async deleteEvent(id) {
    const composedURL = `/api3/schedule/event/${id}/delete`;
    const response = await ApiService.delete(composedURL);
    return response;
  }

  static async eventDone(data) {
    const composedURL = `/api3/schedule/event/${data.id}/done`;
    const response = await ApiService.post(composedURL, data.body);
    return response;
  }

  static async editEvent(data) {
    const composedURL = `/api3/schedule/event/${data.id}/edit`;
    const response = await ApiService.put(composedURL, data.body);
    return response;
  }

  static async excludeDates(data) {
    const composedURL = `/api3/schedule/event/${data.id}/exclude_dates`;
    const response = await ApiService.post(composedURL, data.body);
    return response;
  }

  static async editOneInstance(data) {
    const composedURL = `/api3/schedule/event/${data.id}/edit_one_instance`;
    const response = await ApiService.put(composedURL, data.body);
    return response;
  }

  static async doneOneInstance(data) {
    const composedURL = `/api3/schedule/event/${data.id}/done_one_instance`;
    const response = await ApiService.post(composedURL, data.body);
    return response;
  }
}

export default CalendarService;
