import React from 'react';
import { Avatar, useChannelStateContext } from 'stream-chat-react';
import StreamLogo from '../../../../public/images/stream.png';
import getInitials from '@/components/shared/getInitials';

function AvatarGroup({ members, name }) {
  const { channel } = useChannelStateContext();
  const getCleanImage = (member) => {
    const cleanImage = member.user?.image || '';
    return cleanImage;
  };
  let content = <Avatar image={StreamLogo} shape="square" size={42} />;

  if (members.length === 1) {
    const memberImage = getCleanImage(members[0]);
    content = memberImage ? (
      <Avatar image={memberImage} size={42} />
    ) : (
      <div
        style={{
          width: '42px',
          height: '42px',
          borderRadius: '50%', // Esto crea un círculo
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#ddd', // Puedes elegir un color de fondo
          color: '#222',
          lineHeight: '1',
          fontSize: '28',
          fontWeight: '400',
        }}
      >
        {getInitials(name)}
      </div>
    );
  }

  if (members.length >= 2) {
    content = (
      <div>
        <Avatar image={channel.data.image} shape="square" size={48} />
      </div>
    );
  }

  // fallback for channels with no avatars (single-user channels)
  return (
    <div
      className="avatar-group"
      style={{
        display: 'flex',
        height: '100%',
        alignItems: 'center',
      }}
    >
      {content}
    </div>
  );
}

export default AvatarGroup;
