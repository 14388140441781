import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { StreamChat } from 'stream-chat';
import { showTokkoChat } from '@/store/slices/MessengerSlice';
import { tokkoModalActions } from '@/components/TokkoActions/tokkoActions';

export default function useClient(userData) {
  const tokkoBrokerHost = localStorage.getItem('tokkoBrokerHost');
  const [chatClient, setChatClient] = useState(null);
  const [activeSession, setActiveSession] = useState(1);
  const dispatch = useDispatch();

  const closeModal = () => {
    dispatch(showTokkoChat(false));
    tokkoModalActions({ msg: 'closeTokkoChat' }, tokkoBrokerHost);
  };

  let client;
  let broadcastChannel;
  useEffect(() => {
    const initChat = async () => {
      client = StreamChat.getInstance(userData.userData.instanceId, {
        timeout: 10000,
      });
      const didUserConnectInterrupt = false;
      await client
        .connectUser(userData.userData.info, userData.userData.accessToken)
        .then(() => {
          if (!didUserConnectInterrupt && activeSession === 1) {
            setChatClient(client);
          }
        });
      broadcastChannel = new BroadcastChannel('sessionChannel');
      broadcastChannel.onmessage = (event) => {
        if (
          event.data === 'newSessionOpened' &&
          document.visibilityState !== 'visible'
        ) {
          if (activeSession === 1) {
            setChatClient(null);
            client.disconnectUser();
            setActiveSession(2);
            closeModal();
          }
        }
      };
    };

    initChat();
  }, [userData, activeSession]);

  useEffect(() => {
    broadcastChannel = new BroadcastChannel('sessionChannel');
    broadcastChannel.postMessage('newSessionOpened');
    return () => {
      broadcastChannel.close();
    };
  }, []);

  return chatClient;
}
