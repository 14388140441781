import { Box, Icon } from '@oneloop/jopijs';
import { useEffect, useState } from 'react';
import style from './Checkbox.module.scss';

export default function Checkbox({ checked }) {
  const [check, setCheck] = useState(checked);

  useEffect(() => {
    setCheck(checked);
  }, [checked]);

  return (
    <Box
      className={`${style.container} ${check ? style.checked : ''}`}
      onClick={() => setCheck((prev) => !prev)}
    >
      {check && <Icon icon="icon-aceptar" fontSize="12px" />}
    </Box>
  );
}
