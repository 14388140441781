import React from 'react';
import { useChatContext } from 'stream-chat-react';
import styles from './CustomSystemMessage.module.scss';
import { formatDateToDayTime } from '@/components/shared/DateTransform';

function CustomSystemMessage(props) {
  const { message } = props;
  const {
    assigned_user_text,
    inquiry_generator_user_text,
    assigning_user_text,
    text,
    subtype,
    assigned_user,
    assigning_user,
    inquiry_generator_user,
    created_at,
  } = message;
  const { client } = useChatContext();

  const date = created_at.toString();

  let displayText = text;
  if (subtype === 'assignment') {
    if (assigned_user === client.userID) {
      displayText = assigned_user_text;
    } else if (assigning_user === client.userID) {
      displayText = assigning_user_text;
    } else if (inquiry_generator_user === client.userID) {
      displayText = inquiry_generator_user_text;
    }
  }

  return (
    <div className={styles.customSystemMessage}>
      <div>
        <p className={styles.customSystemMessageText}>{displayText}</p>
        <p className={styles.customSystemMessageText}>
          {formatDateToDayTime(date)}
        </p>
      </div>
    </div>
  );
}

export default CustomSystemMessage;
