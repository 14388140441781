import React, { useEffect, useRef } from 'react';
import { Icon } from '@oneloop/jopijs';

function LinkWithQR({ text }) {
  const qrCodeEl = useRef(null);
  const loadQRCodeScript = () =>
    new Promise((resolve, reject) => {
      if (document.getElementById('qrcode-script')) {
        resolve();
        return;
      }
      const script = document.createElement('script');
      script.src =
        'https://cdn.rawgit.com/davidshimjs/qrcodejs/gh-pages/qrcode.min.js';
      script.id = 'qrcode-script';
      script.onload = () => resolve();
      script.onerror = () =>
        reject(new Error('Failed to load the QRCode script'));
      document.body.appendChild(script);
    });

  useEffect(() => {
    if (text.startsWith('data:image/png;base64,')) return;
    const generateQRCode = async () => {
      try {
        await loadQRCodeScript();
        if (qrCodeEl.current) {
          // eslint-disable-next-line no-new
          new QRCode(qrCodeEl.current, {
            text,
            width: 200,
            height: 200,
            colorDark: '#000000',
            colorLight: '#ffffff',
          });
        }
      } catch (error) {
        console.error('Error loading QRCode script', error);
      }
    };

    generateQRCode();
  }, [text]);

  return (
    <div style={{ position: 'relative', display: 'inline-block' }}>
      <div ref={qrCodeEl} />
      <div />
      {text.startsWith('data:image/png;base64,') && (
        <img src={text} alt="qrImage" />
      )}
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 10,
          background: 'white',
          borderRadius: '100%',
          width: '40px',
          height: '40px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'baseline',
        }}
      >
        <Icon icon="icon-whatsapp" fontSize="36px" color="black" />
      </div>
    </div>
  );
}

export default LinkWithQR;
