import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import ToastComponent from '../ToastComponent/ToastComponent';
import CalendarEvents from '../calendarEvents/CalendarEvents';
import QuickDisplay from '../quickDisplay/QuickDisplay';
import Messenger from '@/components/Messenger/Messenger';

export default function CustomModalsWrapped({ children }) {
  const { showModal: showCal } = useSelector((state) => state.CalendarEvents);
  const { show: showQD } = useSelector((state) => state.QuickDisplaySlice);
  const { show: showChat } = useSelector((state) => state.MessengerSlice);

  const [modalOrder, setModalOrder] = useState([]);

  const handleOrder = (modalKey, show) => {
    if (show) {
      setModalOrder([...modalOrder, modalKey]);
    } else {
      const newOrder = modalOrder.filter((item) => item !== modalKey);
      setModalOrder(newOrder);
    }
  };

  useEffect(() => {
    handleOrder('showCal', showCal);
  }, [showCal]);

  useEffect(() => {
    handleOrder('showQD', showQD);
  }, [showQD]);

  useEffect(() => {
    handleOrder('showChat', showChat);
  }, [showChat]);

  const RenderModal = {
    showCal: <CalendarEvents />,
    showQD: <QuickDisplay />,
    showChat: <Messenger />,
  };

  return (
    <>
      {children}
      <ToastComponent />
      {modalOrder.map((modal) => (
        <div key={modal}>{RenderModal[modal]}</div>
      ))}
    </>
  );
}
