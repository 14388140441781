/* eslint-disable no-use-before-define */
/* eslint-disable no-promise-executor-return */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Initial state
const initialState = {
  badges: [],
};

// Actual Slice
export const LayoutSlice = createSlice({
  name: 'Layout',
  initialState,
  reducers: {
    // Action to set the badges status
    setBadgesState(state, action) {
      state.badges = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setBadgesAsync.fulfilled, (state, action) => {
      state.badges = action.payload;
    });
  },
});

export const { setBadgesState } = LayoutSlice.actions;

export const selectBadgesState = (state) => state.LayoutSlice.badges;
// Thunks
const delay = () => new Promise((resolve) => setTimeout(resolve, 1000));

export const setBadgesAsync = createAsyncThunk(
  'layout/badgesAsync',
  async (badges) => {
    await delay();
    return badges;
  }
);

export default LayoutSlice.reducer;
