import React, { useState, useEffect, useContext } from 'react';
import { Avatar, useChatContext, ChatContext } from 'stream-chat-react';
import { Box, Badge, Tags, Icon } from '@oneloop/jopijs';
import styles from './CustomChannelPreview.module.scss';
import './CustomChannelPreview.scss';
import ImageNext from '@/components/shared/ImageNext';
import iconTokko from '@/public/images/chat/tokkoicon.svg';
import getInitials from '@/components/shared/getInitials';
import { getTimeStamp } from '@/components/shared/DateTransform';

function CustomChannelPreview(props) {
  const {
    channel,
    setActiveChannel,
    displayTitle,
    displayImage,
    messageDeliveryStatus,
    lastMessage, // lastMessage valida si hay mensajes, si viene undefined son chats vacios que no traen mensaje
  } = props;
  const { client } = useChatContext();
  const { messages } = channel.state;
  const messagePreview = messages[messages.length - 1]?.text?.slice(0, 30);
  const messagePreviewSistemMessage = messages[messages.length - 1];

  let displayText = messagePreviewSistemMessage;
  if (displayText?.subtype === 'assignment') {
    if (displayText?.assigned_user === client.userID) {
      displayText = displayText?.assigned_user_text;
    } else if (displayText?.assigning_user === client.userID) {
      displayText = displayText?.assigning_user_text;
    } else if (displayText?.inquiry_generator_user === client.userID) {
      displayText = displayText?.inquiry_generator_user_text;
    }
  }

  const handleOnClick = () => {
    // Marcar el canal como leído si hay un badge visible
    channel.markRead();
    setActiveChannel(channel);
  };

  const [dataBranch, setDataBranch] = useState();
  const [dataUser, setDataUser] = useState(null);
  useEffect(() => {
    // Guarda data del usuario, verifica en el channel si es distinto a mi ID y trae la data del usuario
    // Este caso me sirve para un chat entre dos personas, pero no estoy contemplando si el tipo es team o colleague
    if (Object.keys(channel?.state?.members).length === 2) {
      const memberData =
        client?.userID !== Object.values(channel?.state?.members)[0].user_id
          ? Object.values(channel?.state?.members)[0]
          : Object.values(channel?.state?.members)[1];
      setDataUser(memberData);
      setDataBranch(
        dataUser?.user?.userProfileExtended?.company
          ? dataUser?.user?.userProfileExtended?.company?.name
          : dataUser?.user?.company?.name
      );
    }
  }, [channel, dataUser, dataBranch]);
  const { channel: activeChannel } = useContext(ChatContext);

  return (
    lastMessage && (
      <Box
        onClick={() => handleOnClick()}
        className={
          channel?.id === activeChannel?.id
            ? styles.channelCardSelected
            : styles.channelCard
        }
      >
        {displayImage || channel.data?.guest_image ? (
          <Box
            style={{
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              width: '48px',
              filter: channel?.data?.frozen ? 'grayscale(100%)' : '',
            }}
          >
            <Avatar
              className={styles.avatarStyles}
              image={
                channel.data.type.startsWith('WhatsApp')
                  ? channel.data.guest_image
                  : displayImage
              }
              shape="rounded"
              size={46}
            />
            {channel.data.type.startsWith('WhatsApp') && (
              <Box className={styles.contactTypeIcon}>
                <Box
                  style={{
                    position: 'absolute',
                    borderRadius: '100%',
                    width: '31px',
                    height: '31px',
                    border: '4px solid white',
                    background: '#25D366',
                    right: '10px',
                    top: '15px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Icon icon="icon-whatsapp" fontSize="16px" color="white" />
                </Box>
              </Box>
            )}
            {dataBranch !== client.user.company.name &&
              dataBranch !== undefined && (
                <Box className={styles.contactTypeIcon}>
                  <Box
                    style={{
                      position: 'absolute',
                      borderRadius: '100%',
                      width: '31px',
                      height: '31px',
                      border: '4px solid white',
                      background: '#DF1E02',
                      right: '10px',
                      top: '15px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <ImageNext alt="logo" src={iconTokko} unoptimized />
                  </Box>
                </Box>
              )}
          </Box>
        ) : (
          <Box>
            {channel.data.type.startsWith('WhatsApp') && (
              <Box className={styles.contactTypeIcon}>
                <Box className={styles.contactTypeBox}>
                  <Icon icon="icon-whatsapp" fontSize="16px" color="white" />
                </Box>
              </Box>
            )}
            <div
              className={styles.initialsCircle} // Asegúrate de definir este estilo
              style={{
                width: '48px',
                height: '48px',
                borderRadius: '50%', // Esto crea un círculo
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: '#ddd', // Puedes elegir un color de fondo
                color: '#222',
                lineHeight: '1',
                fontSize: '28',
                fontWeight: '400',
              }}
            >
              {getInitials(
                channel.data.name ||
                  displayTitle ||
                  channel.data?.channel_header?.description ||
                  'Unnamed Channel'
              )}
            </div>
          </Box>
        )}
        <Box className={styles.contactListContainer}>
          {client?.user?.userProfileExtended.company.name !==
            client?.user?.company.name && (
            <Tags variant="dark" noClick style={{ width: 'fit-content' }}>
              {client?.user?.userProfileExtended.company.name}
            </Tags>
          )}
          {channel.data.type.startsWith('WhatsApp') && (
            <Tags
              variant="success"
              noClick
              style={{
                width: 'fit-content',
                border: '1px solid #9EF9CD',
                color: '#0E653C !important',
              }}
            >
              Nueva Consulta
            </Tags>
          )}
          <Box className={styles.nameDateContainer}>
            <Box>
              {dataBranch !== client?.user.company.name &&
                dataBranch !== undefined && (
                  <Tags
                    variant="dark"
                    noClick
                    style={{
                      maxWidth: '150px',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: 'block',
                    }}
                  >
                    {dataBranch}
                  </Tags>
                )}
              {channel.data.type.startsWith('WhatsApp') ? (
                <h4>{channel.data?.channel_header?.phone_number}</h4>
              ) : (
                <h4>
                  {channel.data.name || displayTitle || 'Unnamed Channel'}
                </h4>
              )}
            </Box>
            <p
              className={styles.datePreview}
              style={{
                margin: '0',
                display: 'flex',
              }}
            >
              {getTimeStamp(channel)}
            </p>
          </Box>
          <Box className={styles.unreadContainer}>
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              {messageDeliveryStatus === 'read' && (
                <Icon icon="icon-doblecheck" fontSize="14px" color="#4268EF" />
              )}
              {messageDeliveryStatus === 'delivered' && (
                <Icon icon="icon-doblecheck" fontSize="14px" color="gray" />
              )}
              <p className={styles.messagePreview}>
                {messagePreviewSistemMessage?.subtype === 'assignment'
                  ? displayText
                  : messagePreview}
              </p>
              {messages[messages.length - 1]?.attachments &&
                !messagePreview && (
                  <p className={styles.messagePreview}>
                    <Icon icon="icon-archivos" fontSize="12px" />
                    archivo...
                  </p>
                )}
            </Box>
            {channel.state.unreadCount > 0 && (
              <Badge variant={['badgeNormal', 'secondary']}>
                {channel.state.unreadCount}
              </Badge>
            )}
          </Box>
        </Box>
      </Box>
    )
  );
}

export default CustomChannelPreview;
