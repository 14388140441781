import { Box, Icon, Text, Textarea } from '@oneloop/jopijs';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import ImageNext from '@/components/shared/ImageNext';
import iconMessenger from '@/public/images/quickDisplay/iconMessenger.svg';
import style from '../QuickDisplay.module.scss';
import translations from '../QuickDisplay.trans.json';
import Trans from '@/components/shared/Trans';
import MessengerService from '@/services/MessengerService';
import {
  setMessengerUserDataAsync,
  showTokkoChat,
} from '@/store/slices/MessengerSlice';

export default function ChatButton() {
  const dispatch = useDispatch();
  const [btnClicked, setBtnClicked] = useState(false);
  const [msgSend, setMsgSend] = useState(false);
  const [messageText, setMessageText] = useState(
    Trans(translations, 'QDMsn.IWantToKnowAboutProperty')
  );
  const { id: userId } = useSelector((state) => state.UserSlice.data);
  const { networkId, quickData, buttonChatDisabled } = useSelector(
    (state) => state.QuickDisplaySlice
  );
  const { data: msnData } = useSelector((state) => state.MessengerSlice);
  const { waffle_flags } = useSelector((state) => state.AuthSlice.permissions);
  const { id: openNaventPropId } = useSelector(
    (state) => state.OpenNaventSlice.property
  );

  const { data, original } = quickData;

  try {
    if (
      userId === data.chat_entre_colegas.id ||
      !waffle_flags.includes('talk_to_producer')
    ) {
      return null;
    }
  } catch (error) {
    console.log(error);
    return null;
  }

  useEffect(() => {
    if (!msnData) {
      dispatch(setMessengerUserDataAsync());
    }
  }, []);

  const sendMessage = async () => {
    const IMAGE = [...original.pictures.images];

    if (original.pictures.front_cover_image) {
      IMAGE.unshift(original.pictures.front_cover_image.url);
    }

    const messageData = {
      recipients: data.chat_entre_colegas,
      text: messageText,
      attachments: [
        {
          data: {
            url_image: IMAGE.length ? IMAGE[0] : '',
            address: original.address,
            id: openNaventPropId || original.id,
            property_type: 'property',
            network: networkId,
          },
        },
      ],
    };

    MessengerService.sentProdMessage(messageData);
    setMessageText(Trans(translations, 'QDMsn.IWantToKnowAboutProperty'));
    setBtnClicked(false);
    setMsgSend(true);
  };

  const toggleMsgSent = () => {
    setBtnClicked(false);
    setMsgSend(false);
  };

  return msgSend ? (
    <Box __css={{ marginTop: '16px' }} className={style.msgSentCont}>
      <Icon icon="icon-check" fontSize="24px" />

      <Text variant="body.fontSize14">
        {Trans(translations, 'MessageSent')}.{' '}
        <Box as="span" onClick={() => dispatch(showTokkoChat(true))}>
          {Trans(translations, 'GoToChat')}
        </Box>
      </Text>

      <Icon icon="icon-cerrar" fontSize="16px" onClick={toggleMsgSent} />
    </Box>
  ) : (
    <Box className={`${style.msnContainer} ${btnClicked ? style.open : ''}`}>
      {btnClicked && (
        <Box __css={{ marginTop: '16px' }}>
          <Text
            variant="bodyBold.fontSize14"
            className={style.msnContainerText}
          >
            {Trans(translations, 'QDMsn.ChatWithProducer')}
          </Text>
          <Box className={style.areaCont}>
            <Textarea
              value={messageText}
              onChange={(e) => setMessageText(e.target.value)}
              width="100%"
              height="105px"
            />
          </Box>
        </Box>
      )}

      <Box
        className={`${style.contactBtn} ${
          buttonChatDisabled ? style.btnChatDiable : ''
        }`}
        onClick={() => {
          if (btnClicked) {
            return sendMessage();
          }

          return setBtnClicked(true);
        }}
      >
        <ImageNext alt="logo" src={iconMessenger} unoptimized />
        <Text variant="bodyBold.fontSize14">
          {btnClicked
            ? Trans(translations, 'SendMessage')
            : Trans(translations, 'QDMsn.Contact')}
        </Text>
      </Box>
    </Box>
  );
}
