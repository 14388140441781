import { Box } from '@oneloop/jopijs';
import { useSelector } from 'react-redux';
import style from './ToastComponent.module.scss';
import './ToastComponent.css';
import ToastItem from './ToastItem';

export default function ToastComponent() {
  const { colaToast } = useSelector((state) => state.ToastSlice);

  return (
    <Box className={style.toastCont}>
      {colaToast.map((toast) => (
        <ToastItem
          key={toast.id}
          id={toast.id}
          text={toast.text}
          type={toast.type}
          time={toast.time}
        />
      ))}
    </Box>
  );
}
