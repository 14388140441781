import { Box } from '@oneloop/jopijs';
import { useSelector } from 'react-redux';
import style from '../QuickDisplay.module.scss';
import TitleProperty from './TitleProperty';
import PriceProperty from './PriceProperty';
import BasicInformation from './BasicInformation';
import Atributes from './Attributes';
import Location from './Location';
import Gallery from './Gallery';
import UnitList from './UnitList';
import Temporary from './Temporary';

export default function PropertyDescription() {
  const { isDevelopment } = useSelector((state) => state.QuickDisplaySlice);

  return (
    <Box className={style.propertyDescriptionContainer}>
      <Gallery />
      <TitleProperty />
      {!isDevelopment && <PriceProperty />}
      <Atributes />
      <Temporary />
      {isDevelopment && <UnitList />}
      <BasicInformation />
      <Location />
    </Box>
  );
}
