/* eslint-disable react/no-array-index-key */
/* eslint-disable camelcase */
import { Box, Icon, Image, Text } from '@oneloop/jopijs';
import { useSelector } from 'react-redux';
import style from '../QuickDisplay.module.scss';
import Skeletons from '@/components/Skeletons/Skeletons';
import translations from '../QuickDisplay.trans.json';
import Trans from '@/components/shared/Trans';
import InternalInfoProp from './InternalInfoProp';
import InternalInfoDevelopment from './InternalInfoDevelopment';
import TokkoBrokerNetwork from './TokkoBrokerNetwork';
import ChatButton from './ChatButton';
import DevelopmentInfo from './DevelopmentInfo';

export default function PropertyInfo() {
  const { quickData, isDevelopment } = useSelector(
    (state) => state.QuickDisplaySlice
  );

  const { data } = quickData;

  if (!data) {
    return (
      <Box __css={{ display: 'flex', gap: '16px', flexDirection: 'column' }}>
        <Box className={style.skeletonKebab}>
          <Box as="span" className={style.skeletonImg} />
          <Box>
            <Box
              as="span"
              className={style.skeletonTxt}
              __css={{ marginBottom: '8px' }}
            />
            <Box
              as="span"
              className={style.skeletonTxt}
              __css={{
                borderRadius: '50px',
                height: '16px',
                maxWidth: '153px',
              }}
            />
          </Box>
        </Box>

        <Skeletons
          style={{ maxWidth: '336px', height: '64px', borderRadius: '16px' }}
        />
        <Skeletons
          style={{ maxWidth: '336px', height: '470px', borderRadius: '16px' }}
        />
      </Box>
    );
  }

  const { chat_entre_colegas, internal_info, is_from_network, development } =
    data;
  const { information } = internal_info;

  const goToProducerProfile = () => {
    window.open(
      `${localStorage.getItem('tokkoBrokerHost')}/user/${
        chat_entre_colegas.id
      }/details`,
      '_blank'
    );
  };

  return (
    <Box className={style.kebabCont}>
      {/* Productor information */}
      {chat_entre_colegas && !is_from_network && (
        <Box className={style.cardCont}>
          <Box className={style.card}>
            {chat_entre_colegas.image ? (
              <Image
                className={style.prodImg}
                src={chat_entre_colegas.image}
                variant="circleBig"
              />
            ) : (
              <Box as="span" className={style.userEmptyImg}>
                <Icon icon="icon-contactos" fontSize="16px" />
              </Box>
            )}
            <Box className={style.prodText}>
              <Box>
                <Text variant="bodyBold.fontSize14">
                  {Trans(translations, 'Agent')}
                </Text>
                <Text
                  className={style.underline}
                  variant="body.fontSize14"
                  onClick={goToProducerProfile}
                >
                  {chat_entre_colegas.name}
                </Text>
              </Box>

              {data.branch && (
                <Box as="span">
                  <Icon icon="icon-ubicacion" fontSize="16px" />
                  <Text variant="body.fontSize12">{data.branch}</Text>
                </Box>
              )}
            </Box>
          </Box>
          <ChatButton />
        </Box>
      )}

      {/* Internal information */}
      {internal_info && (
        <Box>
          {isDevelopment ? (
            <InternalInfoDevelopment
              information={information}
              internal_info={internal_info}
            />
          ) : (
            <InternalInfoProp
              information={information}
              internal_info={internal_info}
              development={development}
            />
          )}
        </Box>
      )}

      {/* Network information */}
      {is_from_network && <TokkoBrokerNetwork />}

      {data.development && <DevelopmentInfo />}
    </Box>
  );
}
