import { Box, Text } from '@oneloop/jopijs';
import { useSelector } from 'react-redux';
import Map from '../../map/Map';
import style from '../QuickDisplay.module.scss';
import translations from '../QuickDisplay.trans.json';
import Trans from '@/components/shared/Trans';
import UseResizeWindows from '@/components/shared/Hooks/ResizeWindows';

export default function Location() {
  const { data } = useSelector((state) => state.QuickDisplaySlice.quickData);
  const winWidth = UseResizeWindows();

  if (!data) {
    return null;
  }

  return (
    <Box className={style.locationCont}>
      <Text className={style.BITitle} variant="bodyBold.fontSize14">
        {Trans(translations, 'Location')}
      </Text>
      <Map
        lat={Number(data.geolocation.lat)}
        lng={Number(data.geolocation.lng)}
        width={winWidth >= 1280 ? 770 : 561}
        height={winWidth >= 1280 ? 386 : 310}
      />
    </Box>
  );
}
