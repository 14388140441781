import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import UserService from '@/services/UserService';

export const setPermissionsAsync = createAsyncThunk(
  'auth/permissionsAsync',
  async () => {
    const data = await UserService.getPermissions();
    return data;
  }
);

// Initial state
const initialState = {
  permissions: { waffle_flags: [] },
};

// Actual Slice
export const AuthSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setPermissionsState(state, action) {
      state.permissions = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setPermissionsAsync.fulfilled, (state, action) => {
      state.permissions = action.payload;
    });
  },
});

export const { setPermissionsState } = AuthSlice.actions;

export const selectPermissionsState = (state) => state.AuthSlice.permissions;

export default AuthSlice.reducer;
