import React from 'react';
import { Box } from '@oneloop/jopijs';
import ImageNext from '@/components/shared/ImageNext';
import styles from '../CustomChannelHeader.module.scss';
import iconWhatsapp from '@/public/images/chat/whatsappIcon.svg';
import iconTokko from '@/public/images/chat/tokkoicon.svg';

export default function ProfileIconType({ type }) {
  const DICT = {
    WhatsAppGuest: {
      file: iconWhatsapp,
    },
    Colleague: {
      file: iconTokko,
    },
  };

  const profileData = DICT[type];

  if (!profileData) {
    return null;
  }
  const isWhatsapp = type.startsWith('WhatsApp');

  return (
    <Box className={styles.contactTypeIcon}>
      <Box
        className={`${styles.profileIconType} ${
          isWhatsapp ? styles.Whatsapp : ''
        }`}
      >
        <ImageNext alt="logo" src={profileData.file} unoptimized />
      </Box>
    </Box>
  );
}
