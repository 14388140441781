import { Box } from '@oneloop/box';
import { Icon } from '@oneloop/icons';
import { Loader } from '@oneloop/jopijs';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Text } from '@oneloop/text';
import copy from 'clipboard-copy';
import style from '../QuickDisplay.module.scss';
import List from './List';
import translations from '../QuickDisplay.trans.json';
import Trans from '@/components/shared/Trans';
import { getFichaInfoUrl } from '@/store/slices/components/quickDisplay/services';
import { resetData } from '@/store/slices/components/quickDisplay/QuickDisplaySlice';

export default function LinkForColleage() {
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const { id, fichaInfo, versionFile } = useSelector(
    (state) => state.QuickDisplaySlice
  );

  useEffect(() => {
    if (fichaInfo.url) {
      copy(fichaInfo.url);
    }
  }, [fichaInfo.url]);

  const handleGenerateLink = () => {
    const dataFile = {
      id,
      edited: versionFile === 'edited' ? 'True' : 'False',
      colleage: 'True',
      isEditable: 'False',
    };

    dispatch(getFichaInfoUrl(dataFile));
  };

  const closeModal = () => {
    setShowModal(false);
    dispatch(resetData('fichaInfo'));
  };

  return (
    <Box __css={{ position: 'relative' }}>
      <Box
        className={style.itemHeader}
        onClick={() => setShowModal((prev) => !prev)}
      >
        <Icon icon="icon-link" fontSize="16px" />
        <Text variant={['bodyBold.fontSize12', 'ellipsis']}>
          {Trans(translations, 'LinkForColleague')}
        </Text>
      </Box>

      {showModal && (
        <List
          __css={{ borderColor: '#A2D4E5', borderRadius: '16px' }}
          open={showModal}
          seter={closeModal}
          clickable
        >
          <Box className={style.copyModalCont}>
            <Box className={style.copyHeader}>
              <Text variant="bodyBold.fontSize14">
                {fichaInfo.url ? (
                  <Box as="span">
                    <Icon icon="icon-check" fontSize="24px" />
                    {Trans(translations, 'LinkCopied')}
                  </Box>
                ) : (
                  Trans(translations, 'LinkForColleague')
                )}
              </Text>
              <Icon icon="icon-cerrar" fontSize="16px" onClick={closeModal} />
            </Box>

            <Text variant="body.fontSize12" className={style.copyLink}>
              {fichaInfo.url || Trans(translations, 'LinkWithOutInfo')}
            </Text>

            {!fichaInfo.url && (
              <Box
                className={`${style.exportButton} ${
                  fichaInfo.loading && style.disabled
                }`}
                __css={{ width: 'fit-content', padding: '7px 12px' }}
                onClick={handleGenerateLink}
              >
                {fichaInfo.loading && (
                  <Box className={style.spinnerCont}>
                    <Loader
                      variant="spinner"
                      style={{
                        backgroundImage: 'conic-gradient(#878C9B ,#FFFFFF)',
                        margin: '0px',
                        width: '20px',
                        height: '20px',
                      }}
                    />
                  </Box>
                )}
                <Text variant="bodyBold.fontSize12">
                  {Trans(translations, 'CopyLink')}
                </Text>
              </Box>
            )}
          </Box>
        </List>
      )}
    </Box>
  );
}
