import { Box, Icon, Text } from '@oneloop/jopijs';
import { useDispatch } from 'react-redux';
import translations from '../QuickDisplay.trans.json';
import Trans from '@/components/shared/Trans';
import style from '../QuickDisplay.module.scss';
import { showQuickDisplay } from '@/store/slices/components/quickDisplay/QuickDisplaySlice';
import { tokkoModalActions } from '../../TokkoActions/tokkoActions';

export default function SearchAvailableUnits() {
  const tokkoBrokerHost = localStorage.getItem('tokkoBrokerHost');
  const dispatch = useDispatch();

  const goToSearch = () => {
    tokkoModalActions({ msg: 'searchUnits' }, tokkoBrokerHost);
    dispatch(showQuickDisplay(false));
  };

  return (
    <Box className={style.itemHeader} onClick={goToSearch}>
      <Box className={style.iconText}>
        <Icon icon="icon-buscar" fontSize="16px" />
        <Text variant="bodyBold.fontSize12">
          {Trans(translations, 'SearchAvailableUnits')}
        </Text>
      </Box>
    </Box>
  );
}
