import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Drawer,
  ButtonIcon,
  Text,
  Heading,
  Icon,
  Tags,
  Input,
} from '@oneloop/jopijs';
import { useToggle } from '@oneloop/hooks';
import { Avatar } from 'stream-chat-react';
import styles from '../CustomChannelHeader.module.scss';
import MessengerService from '@/services/MessengerService';
import translations from './AsignInquiry.trans.json';
import Trans from '@/components/shared/Trans';

function AsignInquiry({
  isOpenAsign,
  toggleAsign,
  channelData,
  webContactGeneredBy,
  duplicateContacts,
  clientData,
}) {
  const overlay = false;
  const [isOpenAsignAgent, toggleAsignAgent] = useToggle(false);
  const [text, setText] = useState('');
  const [selectedAgentId, setSelectedAgentId] = useState(null);
  const [selectedAgentName, setSelectedAgentName] = useState(null);
  const [agentsnew, setAgentsnew] = useState([]);
  const [filteredContacts, setFilteredContacts] = useState([]);
  const [noResults, setNoResults] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageFilteredNumber, setPageFilteredNumber] = useState(0);
  const boxRef = useRef(null);
  const boxFilteredRef = useRef(null);

  const { members } = channelData.state;

  const membersArray = Object.values(members);

  const contactUser = channelData.type.startsWith('WhatsApp')
    ? null
    : webContactGeneredBy;

  const fromUser = membersArray[0].user_id;

  const oldChannelCid = channelData.cid;

  const assignOtherAgent = (agentID) => {
    const agentObject = {
      id: (selectedAgentId || agentID).toString(),
      name: selectedAgentName,
    };
    MessengerService.reasignWebContact(channelData?.data?.contact?.id, agentID)
      .then(() => {
        MessengerService.migrateChat(
          contactUser,
          agentObject,
          fromUser,
          oldChannelCid
        );
        toggleAsign();
      })
      .catch((error) => {
        console.error('Error al asignar contacto:', error);
      });
  };

  const assignContactDuplicated = (
    contactAgentSelectedId,
    contactAgentSelectedName
  ) => {
    const duplicateContactObj = {
      id: contactAgentSelectedId.toString(),
      name: contactAgentSelectedName,
    };
    MessengerService.reasignWebContact(
      channelData.data.contact.id,
      contactAgentSelectedId
    )
      .then(() => {
        MessengerService.migrateChat(
          webContactGeneredBy,
          duplicateContactObj,
          fromUser,
          oldChannelCid
        );
        toggleAsign();
      })
      .catch((error) => {
        console.error('Error al asignar contacto:', error);
      });
  };

  const getAgents = async () => {
    try {
      const response = await MessengerService.getAgentsChat('', 0, false);
      setAgentsnew(response.users);
    } catch (error) {
      console.error('Error al obtener los agentes:', error);
    }
  };

  const selectAgent = (agent) => {
    setSelectedAgentId(agent.id);
    setSelectedAgentName(agent.full_name);
  };

  const handleScroll = async () => {
    const { scrollTop, scrollHeight, clientHeight } = boxRef.current;
    if (scrollTop + clientHeight >= scrollHeight) {
      setPageNumber((prevPageNumber) => prevPageNumber + 1);
      try {
        const response = await MessengerService.getAgentsChat(
          '',
          pageNumber + 1,
          false
        );
        setAgentsnew((prevAgents) => [...prevAgents, ...response.users]);
      } catch (error) {
        console.error('Error al obtener los agentes:', error);
      }
    }
  };

  useEffect(() => {
    if (duplicateContacts.length === 0) {
      getAgents();
    }
  }, [duplicateContacts]);

  const handleChange = (e) => {
    const newText = e.target.value;
    setText(newText);
    if (newText.length >= 3) {
      MessengerService.getAgentsChat(newText)
        .then((result) => {
          if (result.users.length === 0) {
            setNoResults(true);
            setFilteredContacts([]);
          } else {
            setNoResults(false);
            setFilteredContacts(result.users);
          }
        })
        .catch((error) => {
          console.error('Error al obtener los agentes:', error);
        });
    } else if (newText === '') {
      setFilteredContacts([]);
      setNoResults(false);
    }
  };

  const handleClearText = () => {
    setText('');
    setFilteredContacts([]);
    setNoResults(false);
  };

  const handleFilteredScroll = async () => {
    const scrollContainer = boxFilteredRef.current;
    if (
      scrollContainer.scrollHeight - scrollContainer.scrollTop ===
      scrollContainer.clientHeight
    ) {
      setPageFilteredNumber(
        (prevPageFilteredNumber) => prevPageFilteredNumber + 1
      );
      try {
        const response = await MessengerService.getAgentsChat(
          text,
          pageFilteredNumber + 1,
          false
        );
        setFilteredContacts((prevAgents) => [...prevAgents, ...response.users]);
      } catch (error) {
        console.error('Error al obtener los agentes:', error);
      }
    }
  };

  // Filtrar agentsnew para excluir al usuario con id igual a clientData.userID
  const filteredAgentsnew = agentsnew.filter(
    (agent) => agent.id !== parseInt(clientData.userID, 10)
  );

  return (
    <Box className={styles.contDrawer}>
      {duplicateContacts.length >= 1 && (
        <Drawer
          isOpenAsign={isOpenAsign}
          screenSide="right"
          animationMinWidth={315}
          animationWidth={315}
          overlay={overlay}
          heightMin="580px"
          animationDuration={10}
          borderRadiusClosed="20px"
          marginTop="0px"
        >
          <Box style={{ padding: '14px 0 0 0' }}>
            <Drawer.Header className={styles.companyProfileBox}>
              <Box
                style={{
                  justifyContent: 'space-between',
                  display: 'flex',
                  alignItems: 'center',
                  width: '200px',
                }}
              >
                <ButtonIcon
                  icon="icon-cerrar"
                  variant="mainButtonIcon"
                  onClick={toggleAsign}
                />
                <Heading as="h5" variant="heading.3">
                  Asignar consulta
                </Heading>
              </Box>
            </Drawer.Header>
            <Drawer.Body
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '5px',
              }}
            >
              <Box className={styles.companyProfileInfoBox}>
                <Text className={styles.asignInquiryDescription}>
                  El teléfono coincide con los siguientes contactos, ¿a cuál le
                  asignamos la consulta?
                </Text>
              </Box>
            </Drawer.Body>
            <Drawer.Footer
              style={{
                width: '318px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '8px',
                maxHeight: '360px',
                height: '360px',
                overflow: 'auto',
                paddingRight: '10px',
              }}
            >
              {duplicateContacts.map((contact) => (
                <Box
                  onClick={() =>
                    assignContactDuplicated(
                      contact.agent.id,
                      contact.agent.full_name
                    )
                  }
                  className={styles.asignInquiryBox}
                >
                  <Box
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      alignSelf: 'center',
                      width: '100%',
                      gap: '8px',
                    }}
                  >
                    <Box
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                        height: '20px',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                      }}
                    >
                      <Heading
                        as="h5"
                        variant="heading.4"
                        style={{
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          maxWidth: '115px',
                        }}
                      >
                        {contact.name}
                      </Heading>
                      <Tags
                        variant="warning"
                        noClick
                        style={{
                          color: contact.lead_status.foreground_color,
                          backgroundColor: contact.lead_status.background_color,
                        }}
                      >
                        {contact.lead_status.name}
                      </Tags>
                    </Box>
                    <Box style={{ display: 'flex', gap: '5px' }}>
                      <Box style={{ display: 'flex' }}>
                        <Icon icon="icon-reasignar" fontSize="16px" />
                        <Text className={styles.asignInquiryTextBold}>
                          Agente
                        </Text>
                      </Box>
                      {contact.agent && contact.agent.full_name && (
                        <Text className={styles.asignInquiryText}>
                          {contact.agent.full_name}
                        </Text>
                      )}
                    </Box>
                  </Box>
                  <Text className={styles.asignInquiryText}>
                    Creado el {contact.get_localtimes.created_at.date}
                  </Text>
                </Box>
              ))}
            </Drawer.Footer>
            <Box
              style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                gap: '8px',
              }}
              onClick={toggleAsignAgent}
            >
              <Box
                style={{ width: '268px', height: '1px', background: '#DDE5E9' }}
              />
              <Box
                onClick={getAgents}
                className={styles.asignInquiryOtherAgent}
              >
                <Icon icon="icon-reasignar" fontSize="16px" />
                <Text className={styles.asignInquiryTextBold}>
                  Asignar a otro agente
                </Text>
              </Box>
            </Box>
          </Box>
        </Drawer>
      )}
      {(duplicateContacts.length === 0 || isOpenAsignAgent) && (
        <Drawer
          isOpenAsign={isOpenAsign}
          screenSide="right"
          animationMinWidth={315}
          animationWidth={315}
          overlay={overlay}
          heightMin="580px"
          animationDuration={10}
          borderRadiusClosed="20px"
          marginTop="0px"
        >
          <Box
            style={{
              padding: '14px 0px 0px',
            }}
          >
            <Drawer.Header className={styles.companyProfileBox}>
              <Box
                style={{
                  justifyContent: 'space-between',
                  display: 'flex',
                  alignItems: 'center',
                  width: '200px',
                }}
              >
                <ButtonIcon
                  icon="icon-cerrar"
                  variant="mainButtonIcon"
                  onClick={toggleAsign}
                />
                <Heading as="h5" variant="heading.3">
                  Asignar consulta
                </Heading>
              </Box>
            </Drawer.Header>
            <Drawer.Body>
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '8px',
                  height: '450px',
                  padding: '10px',
                }}
              >
                <Input
                  prefix={<Icon icon="icon-buscar" fontSize="14px" />}
                  suffix={
                    text !== '' ? (
                      <ButtonIcon
                        variant={['transparentIcon', 'iconExtraSmall22px']}
                        icon="icon-cerrar"
                        onClick={handleClearText}
                      />
                    ) : undefined
                  }
                  placeholder={Trans(translations, 'SearchAgent')}
                  variant="inputSearch"
                  width="275px"
                  onChange={handleChange}
                  value={text}
                />
                {filteredContacts.length > 0 && (
                  <Box
                    ref={boxFilteredRef}
                    onScroll={handleFilteredScroll}
                    className={styles.filteredContactsBox}
                  >
                    {filteredContacts.map((filteredContact) => (
                      <Box
                        onClick={() => selectAgent(filteredContact)}
                        className={styles.filteredContactsAgent}
                        key={filteredContact.id}
                      >
                        <Box
                          style={{
                            display: 'flex',
                            gap: '8px',
                            alignItems: 'center',
                            width: '185px',
                          }}
                        >
                          <Avatar
                            className={styles.avatarStyles}
                            image={`https://ui-avatars.com/api/?name=${filteredContact.full_name}`}
                            shape="rounded"
                            size={46}
                          />
                          <h4 className={styles.asignInquiryAgentText}>
                            {filteredContact?.full_name}
                          </h4>
                        </Box>
                        {selectedAgentId === filteredContact.id && (
                          <Box
                            style={{
                              right: '170px',
                              top: '25px',
                              position: 'relative',
                              width: '24px',
                              height: '24px',
                              padding: '2px',
                              background: '#00D186',
                              border: '4px solid white',
                              borderRadius: '100%',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <Icon
                              icon="icon-aceptar"
                              fontSize="11px"
                              color="white"
                            />
                          </Box>
                        )}
                      </Box>
                    ))}
                  </Box>
                )}
                {noResults && (
                  <Box className={styles.notFoundfilteredContactsBox}>
                    <p>No se encontraron resultados para: {text}</p>
                  </Box>
                )}
                <Box
                  ref={boxRef}
                  onScroll={handleScroll}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '8px',
                    width: '308px',
                    padding: '4px 8px',
                    maxHeight: '380px',
                    overflowY: 'scroll',
                  }}
                >
                  {filteredAgentsnew.map((agent) => (
                    <Box
                      onClick={() => selectAgent(agent)}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '8px',
                        padding: '8px',
                        marginRight: 'auto !important',
                        marginLeft: 'auto !important',
                        width: '260px',
                        height: '62px',
                        cursor: 'pointer',
                      }}
                      className={styles.asignAgentBox}
                      key={agent.id}
                    >
                      <Box
                        style={{
                          display: 'flex',
                          gap: '8px',
                          alignItems: 'center',
                          width: '185px',
                        }}
                      >
                        <Avatar
                          className={styles.avatarStyles}
                          image={`https://ui-avatars.com/api/?name=${agent.full_name}`}
                          shape="rounded"
                          size={46}
                        />
                        <h4 className={styles.asignInquiryAgentText}>
                          {agent.full_name}
                        </h4>
                      </Box>
                      {selectedAgentId === agent.id && (
                        <Box
                          style={{
                            left: '25px',
                            bottom: '25px',
                            position: 'relative',
                            width: '24px',
                            height: '24px',
                            padding: '2px',
                            background: '#00D186',
                            border: '4px solid white',
                            borderRadius: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <Icon
                            icon="icon-aceptar"
                            fontSize="11px"
                            color="white"
                          />
                        </Box>
                      )}
                    </Box>
                  ))}
                </Box>
              </Box>
              <Box style={{ display: 'flex', justifyContent: 'center' }}>
                <Box className={styles.asignInquiryButton}>
                  <Heading
                    style={{ color: 'white' }}
                    Heading
                    as="h5"
                    variant="heading.4"
                    onClick={() => assignOtherAgent(selectedAgentId)}
                  >
                    Asignar Consulta
                  </Heading>
                </Box>
              </Box>
            </Drawer.Body>
          </Box>
        </Drawer>
      )}
    </Box>
  );
}

export default AsignInquiry;
