/* eslint-disable no-unsafe-optional-chaining */
import { Box, Text } from '@oneloop/jopijs';
import { useEffect, useRef, useState } from 'react';
import style from './ToolTip.module.scss';

export default function ToolTip({
  title,
  children,
  place = 'top',
  outSideModal = false,
}) {
  const [hover, setHover] = useState(false);
  const [position, setPosition] = useState({
    bottom: '0px',
    arrow: '0px',
  });
  const refTip = useRef(null);
  const refWind = useRef(null);

  const handleMouse = () => setHover(!hover);

  useEffect(() => {
    const tipPositions = {
      bottom: `-${refTip.current.offsetHeight + 45}px`,
      top: `${refTip.current.offsetHeight + 2}px`,
    };

    if (outSideModal) {
      const modal = document
        .getElementById(outSideModal)
        .getBoundingClientRect();

      const tip = refTip.current.getBoundingClientRect();

      setPosition({
        ...position,
        bottom: 'inherit',
        top: `${tip.top - modal.top - tip.height - 10}px`,
        left: `${
          tip.left -
          modal.left -
          (refWind.current?.offsetWidth - refTip.current.offsetWidth) / 2
        }px`,
      });
    } else {
      setPosition({
        ...position,
        bottom: tipPositions[place],
        arrow: `${(refTip.current.offsetHeight - 14) / 2}px`,
      });
    }
  }, [refTip.current, hover]);

  return (
    <Box
      __css={{ position: outSideModal ? 'inherit' : 'relative' }}
      onMouseEnter={handleMouse}
      onMouseLeave={handleMouse}
      className={style.container}
      ref={refTip}
    >
      {children}

      {hover && (
        <Box
          ref={refWind}
          className={`${style.tip} ${
            outSideModal ? '' : style.nonOutSideModal
          }`}
          style={{
            bottom: position.bottom,
            top: position?.top,
            left: position?.left,
          }}
        >
          <Text variant="body.fontSize12">{title}</Text>
          <Box className={`${style.arrow} ${style[place]}`} />
        </Box>
      )}
    </Box>
  );
}
