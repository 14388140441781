/* eslint-disable @next/next/no-img-element */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import {
  Chat,
  Channel,
  ChannelList,
  MessageList,
  Window,
  MessageInput,
  InfiniteScroll,
  Attachment,
} from 'stream-chat-react';
import { Box, ButtonIcon } from '@oneloop/jopijs';
import { useToggle } from '@oneloop/hooks';
import useClient from '../Hooks/useClient';
import CustomHeader from './CustomHeader/CustomHeader';
import CustomChannelPreview from './CustomChannelPreview/CustomChannelPreview';
import CustomChannelHeader from './CustomChannelHeader/CustomChannelHeader';
import CustomInput from './CustomInput/CustomInput';
import CustomSearch from './CustomSearch/CustomSearch';
import CustomMessage from './CustomMessage/CustomMessage';
import styles from './ChatMessenger.module.scss';
import 'stream-chat-react/dist/css/v2/index.css';
import 'src/components/Messenger/ChatMessenger/ChatMessenger.scss';
import CustomSystemMessage from './CustomSystemMessage/CustomSystemMessage';
import MessengerService from '@/services/MessengerService';
import LinkWithPanel from './LinkWithWhatsapp/LinktWithPanel';
import CustomTypingIndicator from './CustomTypingIndicator/CustomTypingIndicator';
import ChatSkeletons from '../ChatSkeletons/ChatSkeletons';
import CustomDateSeparator from './CustomDateSeparator/CustomDateSeparator';

function ChatMessenger(userData) {
  const chatClient = useClient(userData);
  const [messageReply, setMessageReply] = useState(undefined);
  const [showChannelList, setShowChannelList] = useState(true); // Estado para controlar la visibilidad de ChannelList .
  const [isVincular, setVincular] = useToggle(false);
  // const [isLinktToWhatsapp, setLinktToWhatsapp] = useState(true);

  useEffect(() => {
    MessengerService.deleteUnreadsMessages();
  }, []);

  if (!chatClient) {
    return <ChatSkeletons />;
  }
  const filters = {
    type: {
      $in: [
        'team',
        'Colleague',
        'feedback',
        'WhatsAppGuest',
        'WhatsAppContact',
      ],
    },
    members: { $in: [chatClient.clientID.split('-')[0]] },
  };

  return (
    <Chat theme="" client={chatClient}>
      <Box className={styles.chatContainer}>
        <Box className={styles.channelListContainer}>
          <Box>
            <CustomHeader isVincular={isVincular} setVincular={setVincular} />
          </Box>
          <CustomSearch
            setShowChannelList={setShowChannelList} // Pasa la función para controlar la visibilidad
          />
          {/* {isLinktToWhatsapp && !chatClient.user?.whatsapp.synchronized && (
            <Box className={styles.linkWhatsappBox}>
              <Box className={styles.linkWhatsappInnerBox}>
                <Box
                  style={{
                    width: '42px',
                    height: '42px',
                    borderRadius: '12px',
                    background: '#25D366',
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Icon icon="icon-whatsapp" fontSize="24px" color="white" />
                </Box>
                <Box style={{ display: 'flex', width: '200px' }}>
                  <p className={styles.linkWithText}>
                    Ahora puedes vincular WhatsApp a Tokko Messenger
                  </p>
                  <ButtonIcon
                    onClick={() => setLinktToWhatsapp(false)}
                    style={{
                      color: '#00D186',
                      position: 'absolute',
                      right: '-25px',
                      bottom: '10px',
                    }}
                    icon="icon-cerrar"
                    variant={['subtleTrasnparentIconClearButton', 'iconMedium']}
                  />
                </Box>
              </Box>
              <Button
                onClick={() => setVincular(true)}
                variant={['medium']}
                className={styles.greenButton}
              >
                Vincular
              </Button>
            </Box>
          )} */}
          {isVincular && !chatClient.user?.whatsapp.synchronized && (
            <LinkWithPanel setVincular={setVincular} />
          )}
          {showChannelList && (
            <ChannelList
              filters={filters}
              sort={{ last_message_at: -1 }}
              Preview={CustomChannelPreview}
              Paginator={InfiniteScroll}
            />
          )}
        </Box>
        <Box className={styles.channelContainer}>
          <Channel
            TypingIndicator={CustomTypingIndicator}
            MessageSystem={CustomSystemMessage}
            // eslint-disable-next-line react/no-unstable-nested-components
            Message={(props) => (
              <CustomMessage {...props} setMessageReply={setMessageReply} />
            )}
            DateSeparator={CustomDateSeparator}
          >
            <Window>
              <CustomChannelHeader />
              <MessageList
                messageActions={['']}
                returnAllReadData
                theme="str-chat__channel-list"
              />
              {messageReply && (
                <Box className={styles.responseContainer}>
                  <Box className={styles.responseData}>
                    <p className={styles.responseUserName}>
                      {messageReply.user.name}
                    </p>
                    <p>{messageReply.text}</p>
                    {messageReply?.attachments &&
                      messageReply?.attachments.map((attachment) =>
                        attachment.type === 'image' ? (
                          <img
                            style={{ width: '150px' }}
                            alt={messageReply.text}
                            src={
                              attachment?.image_url ||
                              messageReply?.attachments[0]?.thumb_url
                            }
                          />
                        ) : (
                          <Attachment attachments={[attachment]} />
                        )
                      )}
                  </Box>
                  <Box style={{ margin: '10px' }}>
                    <ButtonIcon
                      icon="icon-cerrar"
                      variant={[
                        'subtleTrasnparentIconClearButton',
                        'iconLarge',
                      ]}
                      onClick={() => setMessageReply(undefined)}
                    />
                  </Box>
                </Box>
              )}
              <MessageInput
                // eslint-disable-next-line react/no-unstable-nested-components
                Input={(props) => (
                  <CustomInput
                    {...props}
                    setMessageReply={setMessageReply}
                    messageReply={messageReply}
                  />
                )}
              />
              {/* <CustomInput
                setMessageReply={setMessageReply}
                messageReply={messageReply}
              /> */}
            </Window>
          </Channel>
        </Box>
      </Box>
    </Chat>
  );
}

export default ChatMessenger;
