import React, { useEffect } from 'react';
import { Modal } from '@oneloop/jopijs';
import { useDispatch, useSelector } from 'react-redux';
import ChatMessenger from './ChatMessenger/ChatMessenger';
import styles from './Messenger.module.scss';
import {
  setMessengerUserDataAsync,
  showTokkoChat,
} from '@/store/slices/MessengerSlice';
import ChatSkeletons from './ChatSkeletons/ChatSkeletons';
import { tokkoModalActions } from '../TokkoActions/tokkoActions';

function Messenger() {
  const dispatch = useDispatch();
  const { data: userData, show } = useSelector((state) => state.MessengerSlice);
  const tokkoBrokerHost = localStorage.getItem('tokkoBrokerHost');

  const closeModal = () => {
    dispatch(showTokkoChat(false));
    tokkoModalActions({ msg: 'closeTokkoChat' }, tokkoBrokerHost);
  };

  useEffect(() => {
    if (show) {
      if (!userData) {
        dispatch(setMessengerUserDataAsync());
      }
    }
  }, [show]);

  return (
    show && (
      <Modal
        className={styles.messengerIframe}
        height="580px"
        width="982px"
        id="TokkoChat"
        fixedCLoseBtn
        modalBG
        blockScroll
        closeModal={closeModal}
        paddings="0px"
      >
        <Modal.Body>
          {userData ? <ChatMessenger userData={userData} /> : <ChatSkeletons />}
        </Modal.Body>
      </Modal>
    )
  );
}
export default Messenger;
