import { Box, Toast } from '@oneloop/jopijs';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import style from './ToastComponent.module.scss';
import './ToastComponent.css';
import { closeToast } from '@/store/slices/components/toast/ToastSlice';

export default function ToastItem({ id, text, time = 3000, type = 'success' }) {
  const [mounted, setMounted] = useState(true);
  const contRef = useRef();
  const dispatch = useDispatch();

  const TYPES = {
    success: {
      variant: 'check',
      icon: 'icon-check',
    },
    alert: {
      variant: 'alert',
      icon: 'icon-alertas',
    },
    danger: {
      variant: 'error',
      icon: 'icon-error',
    },
  };

  let TimeOut;
  const handleClose = (idToast) => {
    const toastIndex = contRef.current.children[0];
    toastIndex.className = `${toastIndex.className} hideToast`;
    clearTimeout(TimeOut);
    setTimeout(() => {
      setMounted(false);
      dispatch(closeToast(idToast));
    }, 500);
  };

  useEffect(() => {
    TimeOut = setTimeout(() => {
      handleClose(id);
    }, time);
  }, []);

  return (
    <Box ref={contRef}>
      {mounted && (
        <Toast
          className={style.toast}
          variant={TYPES[type].variant}
          text={text}
          icon={TYPES[type].icon}
          closeFunction={() => handleClose(id)}
        />
      )}
    </Box>
  );
}
