/* eslint-disable no-use-before-define */
/* eslint-disable no-promise-executor-return */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import MessengerService from '@/services/MessengerService';

export const setMessengerUserDataAsync = createAsyncThunk(
  'userdata/messengerUserDataAsync',
  async () => {
    const data = await MessengerService.getUserData();
    return data;
  }
);

// Initial state
const initialState = {
  data: null,
  show: false,
};

// Actual Slice
export const MessengerSlice = createSlice({
  name: 'userdata',
  initialState,
  reducers: {
    setUserData(state, action) {
      state.data = action.payload;
    },

    showTokkoChat(state, action) {
      state.show = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setMessengerUserDataAsync.fulfilled, (state, action) => {
      state.data = action.payload;
    });
  },
});

export const { setUserData, showTokkoChat } = MessengerSlice.actions;

export const selectMessengerData = (state) => state.MessengerSlice.data;

export default MessengerSlice.reducer;
