/* eslint-disable @next/next/no-img-element */
import React, { useState, useEffect } from 'react';
import { Box, Icon } from '@oneloop/jopijs';
import {
  MessageText,
  // ReactionSelector,
  useMessageContext,
  useChannelStateContext,
  useChatContext,
  useChannelActionContext,
} from 'stream-chat-react';
import styles from './CustomMessage.module.scss';
import './CustomMessage.scss';
import CustomMessageActions from '../CustomMessageActions/CustomMessageActions';
import { getColorForUser } from '@/components/shared/AvatarGenerator';
import CustomAttachemnts from '../CustomAttachments/CustomAttachments';

function CustomMessage({ setMessageReply }) {
  const [parentMessageState, setParentMessage] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [isHovered, setIsHovered] = useState(false);
  // const [isReactionSelectorOpen, setIsReactionSelectorOpen] = useState(false);

  // const reactionSelectorRef = useRef(null);
  // const [messageReactionCount, setMessageReactionCount] = useState(null);

  const { message, readBy } = useMessageContext();
  const { channel } = useChannelStateContext();
  const { client } = useChatContext();
  const { jumpToMessage } = useChannelActionContext();
  const hasAttachments = message.attachments && message.attachments.length > 0;

  // useEffect(() => {
  //   // Agrega un event listener para cerrar el ReactionSelector cuando se hace clic fuera de él
  //   const handleClickOutside = (event) => {
  //     if (
  //       reactionSelectorRef.current &&
  //       !reactionSelectorRef.current.contains(event.target)
  //     ) {
  //       setIsReactionSelectorOpen(false);
  //     }
  //   };

  //   // Agrega el event listener al montar el componente
  //   document.addEventListener('mousedown', handleClickOutside);

  //   // Elimina el event listener al desmontar el componente
  //   return () => {
  //     document.removeEventListener('mousedown', handleClickOutside);
  //   };
  // }, []);

  // function handleOpenReaction() {
  //   setIsReactionSelectorOpen((prevState) => !prevState);
  // }

  // Cambie a este nuevo formato ya que antes solo contemplaba la zona horaria de AR-Buenos Aires
  const horaFormateada = message?.created_at?.toLocaleTimeString([], {
    hour12: false,
    hour: '2-digit',
    minute: '2-digit',
  });

  useEffect(() => {
    // Obtener el mensaje al que se responde si message.parent_id está presente
    if (message.quoted_message_id) {
      client
        .getMessage(message.quoted_message_id)
        .then((parentMessage) => {
          setParentMessage(parentMessage);
        })
        .catch(() => {
          // console.error('Error al obtener el mensaje padre:', error);
        });
    }
  }, [message.quoted_message_id]);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  // const palabrasReacciones = message?.reaction_counts
  //   ? Object.keys(message.reaction_counts)
  //   : [];
  // // Objeto que mapea palabras a emojis
  // const emojis = {
  //   wow: '😲',
  //   haha: '😂',
  //   angry: '😠',
  //   sad: '😢',
  //   love: '❤️',
  //   like: '👍',
  //   // Agrega otras palabras y emojis si es necesario
  // };

  // Utiliza el método map para generar elementos <span> con emojis en lugar de palabras
  // const reaccionSpans = palabrasReacciones.map((reaccion, index) => (
  //   // eslint-disable-next-line react/no-array-index-key
  //   <Box
  //     style={{
  //       flexWrap: 'wrap',
  //       alignContent: 'center',
  //       position: 'relative',
  //       margin: '2px',
  //       alignItems: 'center',
  //       display: 'flex',
  //       justifyContent: 'center',
  //     }}
  //     // eslint-disable-next-line react/no-array-index-key
  //     key={index}
  //   >
  //     {emojis[reaccion] || reaccion}
  //   </Box>
  // ));

  const justReadByMe =
    readBy.length === 1 && readBy[0] && readBy[0].id === client.userID;
  const sending = message.status === 'sending';
  const delivered = message.status === 'received';
  const deliveredAndRead = !!(readBy?.length && !justReadByMe);

  const myMessage = message.user.id === client.userID;

  // const customReactionHandler = async (reactionType) => {
  //   try {
  //     // Obtiene las reacciones existentes para el mensaje específico
  //     const existingReactions = await channel.getReactions(message.id);
  //     // Obtiene un array de tipos de reacciones
  //     const existingReactionTypes = existingReactions.reactions.map(
  //       (item) => item.type
  //     );
  //     // Compara si reactionType está presente en el array de tipos de reacciones
  //     if (existingReactionTypes.includes(reactionType)) {
  //       // Se elimina la reaccion ya existente
  //       await channel.deleteReaction(message.id, reactionType);
  //     } else {
  //       // Si no existe, envía la nueva reacción al mensaje específico
  //       await channel.sendReaction(message.id, { type: reactionType });
  //     }
  //     // Cierra el selector
  //     setIsReactionSelectorOpen(false);
  //   } catch (error) {
  //     // console.error('Error al enviar la reacción:', error);
  //   }
  // };
  // Función para generar un color aleatorio

  useEffect(() => {
    // Actualiza el mapa de colores cuando cambia el usuario del mensaje
    getColorForUser(message.user?.id);
  }, [message.user?.id]);
  //  if (message?.attachments[0]?.type === 'file') {
  //    console.log('mensaje',message?.attachments[0]);
  //  }
  // if (message?.attachments[0]?.type === 'file') {
  //   message?.attachments.map((mensaje) =>
  //     console.log('imagenes', mensaje.type, mensaje.title)
  //   );
  // }
  // if (message?.attachments[0]?.type === 'image') {
  //   console.log(message.attachments);
  // }

  return (
    message && (
      <Box>
        {/* {isReactionSelectorOpen && (
          <Box
            className={
              message.user.id !== client.userID
                ? styles.reactionSelectorBox
                : styles.reactionSelectorBoxClient
            }
          >
            <ReactionSelector
              ref={reactionSelectorRef}
              handleReaction={customReactionHandler}
            />
          </Box>
        )} */}
        <Box
          className={
            message.user.id !== client.userID || message?.from_guest_user
              ? styles.messageContainerBox
              : styles.messageContainerBoxClient
          }
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <Box
            className={
              message.user.id !== client.userID
                ? styles.messageWrapper
                : styles.messageWrapperClient
            }
          >
            <Box
              className={
                message.user.id !== client.userID
                  ? styles.messageWrapperContent
                  : styles.messageWrapperClientContent
              }
            >
              <Box
                className={
                  message.user.id !== client.userID || message?.from_guest_user
                    ? styles.messageContainerUser
                    : styles.messageContainerClient
                }
                style={{
                  backgroundColor: message?.is_history ? '#FFE3C1' : null,
                }}
              >
                {/* cuando es un mensaje de respuesta */}
                {parentMessageState && (
                  <Box
                    className={[
                      message.user.id !== client.userID
                        ? styles.responseMessage
                        : styles.responseMessageClient,
                      styles.cursorPointerStyle,
                    ]}
                    onClick={async () => {
                      jumpToMessage(parentMessageState?.message?.id); // Al hacer click en el mensaje respondido salta al mensaje original al que se respondió
                    }}
                  >
                    <p className={styles.responseUserName}>
                      {parentMessageState.message.user.name}
                    </p>
                    <p
                      className={
                        message.user.id !== client.userID
                          ? styles.responseText
                          : styles.responseTextClient
                      }
                    >
                      {parentMessageState?.message?.attachments[0]
                        ?.image_url && (
                        <img
                          style={{ width: '150px' }}
                          alt={parentMessageState?.message?.text}
                          src={
                            parentMessageState?.message?.attachments[0]
                              .image_url
                          }
                        />
                      )}
                      {parentMessageState?.message?.text}
                    </p>
                    {parentMessageState.message.attachments[0]?.title && (
                      <CustomAttachemnts message={parentMessageState.message} />
                    )}
                  </Box>
                )}
                {/* cuando el canal es un grupo */}
                {channel.data.member_count >= 3 &&
                  message.user.id !== client.userID && (
                    <p
                      className={styles.messageName}
                      style={{ color: getColorForUser(message.user.id) }}
                    >
                      {message.user.name}
                    </p>
                  )}
                {channel?.data?.type.startsWith('WhatsApp') &&
                  !message?.from_guest_user && (
                    <p
                      className={styles.messageName}
                      style={{ color: getColorForUser(message.user.id) }}
                    >
                      {message.user.name}
                    </p>
                  )}
                {channel?.data?.type === 'Colleague' && message?.is_history && (
                  <p
                    className={styles.messageName}
                    style={{ color: getColorForUser(message?.user.id) }}
                  >
                    {message?.user?.name}
                  </p>
                )}

                <Box className={styles.boxCenter}>
                  <CustomAttachemnts message={message} />
                </Box>

                <Box
                  className={
                    hasAttachments
                      ? styles.textTimeBoxImage
                      : styles.textTimeBox
                  }
                >
                  <Box
                    className={
                      channel.data.member_count >= 3 &&
                      message.user.id !== client.userID
                        ? styles.messageText
                        : styles.messageTextClient
                    }
                    style={{
                      padding:
                        channel.data.member_count >= 3
                          ? '0px 8px 1px 8px'
                          : '0px 8px 1px 8px',
                    }}
                  >
                    <Box
                      style={{
                        paddingTop:
                          channel.data.member_count >= 3 &&
                          message.user.id !== client.userID
                            ? '0px'
                            : '5px',
                      }}
                    >
                      <MessageText />
                    </Box>
                  </Box>
                  {/* {message.latest_reactions?.length >= 1 && (
                    <Box className={styles.reactionBox}>{reaccionSpans}</Box>
                  )} */}
                  <Box className={styles.timeBoxMessage}>
                    <span className={styles.messageTime}>{horaFormateada}</span>
                    {deliveredAndRead && myMessage && (
                      <Icon
                        icon="icon-doblecheck"
                        fontSize="14px"
                        color="#4268EF"
                      />
                    )}
                    {sending && myMessage && (
                      <Icon
                        icon="icon-aceptar"
                        fontSize="14px"
                        color="#143742"
                      />
                    )}
                    {delivered && !deliveredAndRead && myMessage && (
                      <Icon
                        icon="icon-doblecheck"
                        fontSize="14px"
                        color="#143742"
                      />
                    )}
                  </Box>
                </Box>
                <Box
                  className={
                    message.user.id !== client.userID
                      ? styles.messageOptionsContainer
                      : styles.messageOptionsContainerClient
                  }
                >
                  {/* {showDetailedReactions && (
                  <Box className={styles.messageReactionClient}>
                    <ReactionSelector />
                  </Box>
                  )} */}
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            className={
              message.user.id !== client.userID
                ? styles.reactionContainerClient
                : styles.reactionContainer
            }
          >
            <Box
              className={
                message.user.id !== client.userID
                  ? styles.clientOptionsContainer
                  : styles.optionsContainer
              }
            >
              {!channel?.data?.frozen && isHovered && (
                <CustomMessageActions
                  client={client}
                  setMessageReply={setMessageReply}
                />
              )}
            </Box>
          </Box>
          {/* {!channel?.data?.frozen && isHovered && (
            <Box
              className={
                message.user?.id !== client.user.id
                  ? styles.optionsContainerClient
                  : styles.optionsContainer
              }
              onClick={() => {
                handleOpenReaction();
              }}
            >
              <Icon icon="icon-positivo" fontSize="24px" color="#485C66" />
            </Box>
          )} */}
        </Box>
      </Box>
    )
  );
}

export default CustomMessage;
