import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  colaToast: [],
};

export const ToastSlice = createSlice({
  name: 'Toast',
  initialState,
  reducers: {
    showToast(state, action) {
      state.colaToast = [
        ...state.colaToast,
        { id: state.colaToast.length, ...action.payload },
      ];
    },
    closeToast(state, action) {
      const nuevaCola = state.colaToast.filter(
        (cola) => cola.id !== action.payload
      );

      state.colaToast = nuevaCola;
    },
  },
});

export const { showToast, closeToast } = ToastSlice.actions;

export default ToastSlice.reducer;
