import {
  Box,
  Icon,
  Image,
  Input,
  Loader,
  Text,
  Textarea,
} from '@oneloop/jopijs';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import Skeletons from '@/components/Skeletons/Skeletons';
import style from '../QuickDisplay.module.scss';
import translations from '../QuickDisplay.trans.json';
import Trans from '@/components/shared/Trans';
import {
  contactRealState,
  getInfoForContact,
  onChangeUser,
  resetForm,
  toggleSent,
} from '@/store/slices/components/openNavent/OpenNaventSlice';
import ChatButton from './ChatButton';

export default function ZonaPropForm() {
  const { id } = useSelector((state) => state.QuickDisplaySlice);
  const { loadingData, property, user, loadingSend, sent, portalLogo } =
    useSelector((state) => state.OpenNaventSlice);
  const dispatch = useDispatch();
  const [btnClicked, setBtnClicked] = useState(false);
  const [activeForm, setActiveForm] = useState(false);
  const [custonMessage, setMessage] = useState(
    Trans(translations, 'ZPForm.IWantToContact')
  );
  const [errorMail, setErrorMail] = useState(undefined);

  useEffect(() => {
    dispatch(getInfoForContact(id));

    return () => dispatch(resetForm());
  }, []);

  if (loadingData) {
    return (
      <Skeletons
        style={{ maxWidth: '336px', height: '182px', borderRadius: '16px' }}
      />
    );
  }

  const handleChange = (e) =>
    dispatch(onChangeUser({ name: e.target.name, value: e.target.value }));

  const isValidMail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSentMsg = () => {
    dispatch(toggleSent());
    setBtnClicked(false);
    setActiveForm(false);
  };

  const sendForm = (e) => {
    e.preventDefault();
    if (!activeForm) {
      return false;
    }

    const { email, phone, fullName, message } = e.target.elements;

    const data = {
      phone: phone.value,
      message: message.value,
      posting_hash: id,
      name: fullName.value,
      email: email.value,
    };

    if (!isValidMail(email.value)) {
      return setErrorMail(Trans(translations, 'EnterAValidEmail'));
    }
    setErrorMail(undefined);

    return dispatch(contactRealState(data));
  };

  const sentForm = () =>
    sent ? (
      <Box __css={{ marginTop: '16px' }} className={style.msgSentCont}>
        <Icon icon="icon-check" fontSize="24px" />

        <Text variant="body.fontSize14">
          {Trans(translations, 'InquirySent')}.
        </Text>

        <Icon icon="icon-cerrar" fontSize="16px" onClick={handleSentMsg} />
      </Box>
    ) : (
      <form className={style.formZP} onSubmit={sendForm}>
        <Box
          className={`${style.portalContainer} ${btnClicked ? style.open : ''}`}
        >
          <Box className={style.label}>
            <Text className={style.gray1} variant="bodyBold.fontSize12">
              Email
            </Text>
            <Input
              infoAlert={errorMail}
              value={user.email}
              name="email"
              maxLength="60"
              type="text"
              autoComplete="off"
              onChange={handleChange}
            />
          </Box>

          <Box className={style.phoneBlock}>
            <Box className={style.label}>
              <Text className={style.gray1} variant="bodyBold.fontSize12">
                {Trans(translations, 'Name')}
              </Text>
              <Input
                value={user.fullName}
                onChange={handleChange}
                name="fullName"
                maxLength="60"
                type="text"
                autoComplete="off"
              />
            </Box>
            <Box __css={{ width: '200px' }} className={style.label}>
              <Text className={style.gray1} variant="bodyBold.fontSize12">
                {Trans(translations, 'Phone')}
              </Text>
              <Input
                value={user.phone}
                onChange={handleChange}
                name="phone"
                maxLength="20"
                type="text"
                autoComplete="off"
              />
            </Box>
          </Box>

          <Box>
            <Box className={style.label}>
              <Text className={style.gray1} variant="bodyBold.fontSize12">
                {Trans(translations, 'Message')}
              </Text>
              <Textarea
                value={custonMessage}
                onChange={(e) => setMessage(e.target.value)}
                name="message"
                width="100%"
                height="70px"
              />
            </Box>
          </Box>
        </Box>
        <Box
          __css={{
            display: 'flex',
            justifyContent: 'end',
            marginTop: '8px',
          }}
        >
          {loadingSend ? (
            <Loader
              variant="spinner"
              style={{
                backgroundImage: 'conic-gradient(#878C9B ,#FFFFFF)',
                margin: '0px',
                width: '32px',
                height: '32px',
              }}
            />
          ) : (
            <Box
              as="button"
              type="submit"
              className={style.contactBtnPortals}
              onClick={() => {
                if (btnClicked) {
                  return setActiveForm(true);
                }

                return setBtnClicked(true);
              }}
            >
              <Text variant="bodyBold.fontSize14">
                {Trans(translations, 'QDMsn.Contact')}
              </Text>
            </Box>
          )}
        </Box>
      </form>
    );

  return (
    <Box className={style.cardCont}>
      <>
        <Box className={style.formZP}>
          <Image
            src={portalLogo}
            alt="logo"
            variant="rows"
            width={100}
            height={30}
          />

          <Box className={style.phoneBlock}>
            <Image
              src={property.companyLogo}
              variant="smallCard"
              className={style.devImg}
            />

            <Text
              style={{ width: '235px' }}
              className={style.gray1}
              variant="bodyBold.fontSize12"
            >
              {Trans(translations, 'ZPForm.Contact')} {property.createdBy}{' '}
              {Trans(translations, 'ZPForm.forThisProperty')}
            </Text>
          </Box>
        </Box>

        {property.isTokkoUser ? <ChatButton /> : sentForm()}
      </>
    </Box>
  );
}
