import React, { useRef, useState, useEffect } from 'react';
import { useChatContext } from 'stream-chat-react';
import {
  Box,
  Image,
  Drawer,
  Text,
  ButtonIcon,
  ParentSubmenu,
  Icon,
  Input,
} from '@oneloop/jopijs';
import { useToggle } from '@oneloop/hooks';
import useOnClickOutside from '@/components/shared/Hooks';
import styles from './CustomHeader.module.scss';
import MessengerService from '@/services/MessengerService';

// export default function CustomHeader({ setVincular }) {
export default function CustomHeader() {
  const { client } = useChatContext();
  const [isOpen, toggle] = useToggle(false);
  const [isToggleState, toggleState] = useToggle(false);
  const [isOpenEdit, toggleEdit] = useToggle(false);
  const [name, setName] = useState(client.user.name);
  const [email, setEmail] = useState(client.user.email);
  const [active, setActive] = useState(false);
  const overlay = false;
  // console.log('clientuserINvisible', client.user.invisible);
  // console.log('useronline', client.user.online);
  const [image, setImage] = useState('');
  const [dropdown, setDropdown] = useState(false);

  useEffect(() => {
    // Solo ejecuta si is_online es undefined
    if (client.user?.is_online === undefined) {
      client.partialUpdateUser({
        id: client.user.id,
        set: {
          is_online: true,
        },
      });
    }
  }, []);

  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];
    if (selectedImage) {
      const reader = new FileReader();
      reader.readAsDataURL(selectedImage);
      reader.onloadend = () => {
        setImage(reader.result);
      };
    }
  };
  const handleOfflineClick = async () => {
    // become invisible
    // console.log('se puso offline', client.user.invisible);
    await client.partialUpdateUser({
      id: client.user.id,
      set: {
        is_online: false,
      },
    });
    toggleState(false);
  };
  const handleOnlineClick = async () => {
    // become invisible
    // console.log('se puso online', client.user.invisible);
    await client.partialUpdateUser({
      id: client.user.id,
      set: {
        is_online: true,
      },
    });
    toggleState(false);
  };

  const fileInputRef = useRef(null);
  const handleEditClick = () => {
    // Simular el clic del input de tipo file
    fileInputRef.current.click();
  };

  const refSubmenu = useRef();
  useOnClickOutside(refSubmenu, dropdown, setDropdown);

  const refBotonEstado = useRef();
  useOnClickOutside(refBotonEstado, isToggleState, toggleState);

  const handleUserOptions = async () => {
    toggle();
    setDropdown(false);
  };
  function parsePhoneNumber(phoneNumber) {
    const areaCodes = ['54', '51', '52', '55', '57', '593', '598', '595'];

    // Verificar si el número de teléfono comienza con uno de los códigos de área
    const foundCode = areaCodes.find((code) => phoneNumber?.startsWith(code));

    if (foundCode) {
      // Obtener el código de área y el número restante
      const areaCode = foundCode;
      const number = phoneNumber.slice(foundCode.length);

      return { areaCode, number };
      // eslint-disable-next-line no-else-return
    } else {
      // Si no se encuentra ningún código de área válido
      return { areaCode: '', number: phoneNumber };
    }
  }
  const parsedPhoneNumber = parsePhoneNumber(client.user.phone_number);
  let users = '';

  if (
    client.user &&
    client.user.groups &&
    client.user.groups[0].preview_members
  ) {
    const previewMembers = client.user.groups[0].preview_members;

    if (previewMembers.length > 3) {
      users = `${previewMembers.slice(0, 3).join(', ')} ...`;
    } else {
      users = previewMembers.join(', ');
    }
  }
  /* console.log(client.user.userProfileExtended); */
  const initialPhoneNumber = parsePhoneNumber(client.user.phone_number);
  const [countryCode, setCountryCode] = useState(initialPhoneNumber.areaCode);
  const [phoneNumber, setPhoneNumber] = useState(initialPhoneNumber.number);

  const combinedNumber = `${countryCode}${phoneNumber}`;
  const sendData = async () => {
    MessengerService.postUserData(name, email, image, combinedNumber);
    toggleEdit();
  };

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const groupData = await MessengerService.getGroupData();
  //       console.log('Datos del grupo:', groupData);
  //     } catch (error) {
  //       console.error('Error al obtener datos del grupo:', error);
  //     }
  //   };
  //   fetchData();
  // }, []);
  // const handleLinktWhatsappClick = () => {
  //   setVincular(true);
  //   setDropdown(false);
  // };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: '72px',
        padding: '24px 0 16px 0',
      }}
    >
      <Image
        className={styles.avatarStylesProfile}
        src={client.user.image || 'invalid_route'}
        variant="circleBig"
      />
      {/* <Box
        className={
          client.user?.is_online === true
            ? styles.circle
            : styles.circleDisabled
        }
      /> */}
      <Box className={styles.userInfoBox}>
        <Box className={styles.userInfo}>
          <h4>{client.user.name}</h4>
          <p>{client.user.company.name}</p>
        </Box>
        <Box>
          <ButtonIcon
            icon="icon-trespunticos"
            variant={['dropdownTransparent', 'iconLarge']}
            onClick={() => setDropdown(true)}
          />
          {dropdown && (
            <Box className={styles.userOptions} ref={refSubmenu}>
              <Box className={styles.userOptionsBox}>
                <Box style={{ width: '235px' }}>
                  <ParentSubmenu
                    id="parent13"
                    aria-describedby="tooltip13"
                    offset={0}
                    placement="right"
                  >
                    <Box
                      onClick={() => setActive((prevState) => !prevState)}
                      holdPress
                      active={active}
                    >
                      {/* <Box>
                        <Text
                          className={styles.userSubmenuStateText}
                          onClick={toggleState}
                        >
                          Cambiar mi estado
                          <Icon
                            style={{ transform: 'scaleX(-1)' }}
                            icon="icon-atras"
                            fontSize="12px"
                          />
                        </Text>
                      </Box> */}
                    </Box>
                  </ParentSubmenu>
                </Box>
                <Box>
                  <Text
                    onClick={handleUserOptions}
                    className={styles.userSubmenuText}
                  >
                    Ver mi perfil
                  </Text>
                  {/* <Text
                    onClick={handleLinktWhatsappClick}
                    className={styles.userSubmenuText}
                  >
                    <Icon
                      icon="icon-whatsapp"
                      fontSize="16px"
                      color="#00D186"
                    />
                    Vincular WhatsApp
                  </Text> */}
                </Box>
              </Box>
            </Box>
          )}
          {isToggleState && (
            <div
              className={styles.userOptionsStatus}
              ref={refBotonEstado}
              // style={{
              //   position: 'absolute',
              //   width: '200px',
              //   zIndex: '5',
              //   background: 'white',
              //   left: '520px',
              //   boxShadow: '0px 0px 4px rgba(0,0,0,0.12)',
              //   borderRadius: '10px',
              // }}
            >
              <ButtonIcon
                variant="submenu"
                text="En Linea"
                maxWidth
                onClick={handleOnlineClick}
              />
              <ButtonIcon
                variant="submenu"
                text="Ausente"
                maxWidth
                onClick={handleOfflineClick}
              />
            </div>
          )}
        </Box>
      </Box>
      {isOpen && (
        <Box className={styles.contDrawer}>
          <Drawer
            isOpen={isOpen}
            screenSide="right"
            animationMinWidth={315}
            animationWidth={315}
            overlay={overlay}
            heightMin="580px"
            animationDuration={10}
            borderRadiusClosed="5px"
            marginTop="0px"
          >
            <Drawer.Header>
              <Box className={styles.profileActionsBox}>
                <ButtonIcon
                  icon="icon-cerrar"
                  variant="mainButtonIcon"
                  onClick={toggle}
                />
                <ButtonIcon
                  icon="icon-editar"
                  onClick={toggleEdit}
                  variant="mainButtonIcon"
                />
              </Box>
            </Drawer.Header>
            <Drawer.Body
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '5px',
              }}
            >
              <Box className={styles.profileInfoBox}>
                <Image
                  className={styles.avatarProfile}
                  src={client.user.image || 'invalid_route'}
                  variant="circleBig"
                />
                <Text className={styles.avatarTitle}>{client.user.name}</Text>
                {client.user.phone_number && (
                  <Text className={styles.avatarText}>
                    +{client.user.phone_number}
                  </Text>
                )}
                <Text className={styles.avatarText}>{client.user.email}</Text>
              </Box>
              {/* <Box className={styles.avatarStatus}>
                <Box
                  className={
                    client?.user?.is_online === true
                      ? styles.profileCircle
                      : styles.profileCircleDisabled
                  }
                />
                {client?.user?.is_online && (
                  <Text className={styles.avatarProfileStatus}>En Linea</Text>
                )}
                {!client?.user?.is_online && (
                  <Text className={styles.avatarProfileStatus}>Ausente</Text>
                )}
              </Box> */}
            </Drawer.Body>
            <Drawer.Footer
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                gap: '20px',
                alignItems: 'center',
              }}
            >
              <Box className={styles.companyBox}>
                <Image
                  className={styles.companyImage}
                  src={
                    client.user.userProfileExtended.company.logo ||
                    'invalid_route'
                  }
                  variant="circleBig"
                />
                <Box className={styles.companyName}>
                  <Text className={styles.avatarCompanyTitle}>
                    {client.user.company.name}
                  </Text>
                  <Text className={styles.avatarCompanyText}>
                    {client.user.userProfileExtended.company.branch.name}
                  </Text>
                </Box>
              </Box>
              <Box style={{ width: '275px' }}>
                <Text className={styles.groupAmountText}>1 Grupo</Text>
                <Box className={styles.groupBox}>
                  <Image
                    className={styles.groupIcon}
                    src={
                      client.user.userProfileExtended.company.logo ||
                      'invalid_route'
                    }
                    variant="avatar"
                  />
                  <Box>
                    <Text className={styles.groupText}>
                      {client.user.company.name}
                    </Text>
                    <Text className={styles.colleaguesInGroup}>{users}</Text>
                  </Box>
                </Box>
              </Box>
            </Drawer.Footer>
          </Drawer>
        </Box>
      )}

      {isOpenEdit && (
        <Box className={styles.contDrawer}>
          <Drawer
            isOpen={isOpenEdit}
            screenSide="right"
            animationMinWidth={315}
            animationWidth={315}
            overlay={overlay}
            heightMin="580px"
            animationDuration={10}
            borderRadiusClosed="5px"
            marginTop="0px"
          >
            <Drawer.Header>
              <Box className={styles.profileActionsBox}>
                <ButtonIcon
                  icon="icon-cerrar"
                  variant="mainButtonIcon"
                  onClick={toggleEdit}
                />
              </Box>
            </Drawer.Header>
            <Drawer.Body
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '5px',
              }}
            >
              <Box className={styles.profileInfoBox}>
                <Image
                  className={styles.avatarProfile}
                  src={image || client.user.image}
                  variant="circleBig"
                />
                <div style={{ position: 'relative', display: 'inline-block' }}>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                  />
                  <button
                    type="button"
                    onClick={handleEditClick}
                    className={styles.editImageButton}
                  >
                    Editar
                  </button>
                </div>
                <Box
                  style={{
                    gap: '20px',
                    display: 'flex',
                    flexDirection: 'column',
                    width: '270px',
                  }}
                >
                  <Box>
                    <Text className={styles.editProfileLabel}>Nombre</Text>
                    <Input
                      className={styles.inputVariant}
                      prefix={<Icon icon="icon-contactos" fontSize="14px" />}
                      placeholder={client.user.name}
                      width="265px"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </Box>
                  <Box>
                    <Text className={styles.editProfileLabel}>Email</Text>
                    <Input
                      className={styles.inputVariant}
                      prefix={<Icon icon="icon-empresa" fontSize="14px" />}
                      placeholder={client.user.email}
                      width="265px"
                      variant="inputTransparent"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Box>
                  <Box className={styles.contactProfileBox}>
                    <Box>
                      <Text className={styles.editProfileLabel}>Pais</Text>
                      <Input
                        className={styles.inputVariant}
                        prefix={<Icon icon="icon-telefono" fontSize="14px" />}
                        placeholder={
                          parsedPhoneNumber.areaCode
                            ? `+${parsedPhoneNumber.areaCode}`
                            : '+000'
                        }
                        onChange={(e) => setCountryCode(e.target.value)}
                        value={countryCode}
                        width="100px"
                        variant="inputTransparent"
                      />
                    </Box>
                    <Box>
                      <Text className={styles.editProfileLabel}>Telefono</Text>
                      <Input
                        className={styles.inputVariant}
                        placeholder={parsedPhoneNumber.number}
                        width="160px"
                        variant="inputTransparent"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Drawer.Body>
            <Drawer.Footer
              style={{
                justifyContent: 'center',
              }}
            >
              <button
                onClick={() => sendData()}
                className={styles.saveProfileChanges}
                type="button"
              >
                Guardar
              </button>
            </Drawer.Footer>
          </Drawer>
        </Box>
      )}
    </div>
  );
}
